import {
  CLOSE_CATEGORY_DIALOG,
  CREATE_NEW_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORY,
  EDIT_CATEGORY_ACTIVE,
  OPEN_CATEGORY_DIALOG,
} from "./types";

const initialState = {
  category: [],
  dialog: false,
  dialogData: null,
};

export const giftCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY:


      return {
        ...state,
        category: action.payload,
      };
    case EDIT_CATEGORY_ACTIVE:
      return {
        ...state,
        category: state.category.map((category) => {
          if (category._id === action.payload.id) return action.payload.data;
          else return category;
        }),
      };
    case CREATE_NEW_CATEGORY:
      const data = [...state.category];
      data.unshift(action.payload);
      return {
        ...state,
        category: data,
      };
    case EDIT_CATEGORY:
      return {
        ...state,
        category: state.category.map((category) => {
          if (category._id === action.payload.id) return action.payload.data;
          else return category;
        }),
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        category: state.category.filter(
          (category) => category._id !== action.payload
        ),
      };
    case OPEN_CATEGORY_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_CATEGORY_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};
