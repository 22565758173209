import React, { useEffect, useState } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { permissionError } from "../../util/Alert";
import Button from "../../extra/Button";
import { createNewBanner, editNewBanner } from "../../store/vipBanner/banner.action";

import { baseURL } from "../../util/config";
import Male from "../../../assets/images/male.jpg";
import Select from "react-select";

import CancelIcon from "@mui/icons-material/Cancel"

const VipBannerDialogue = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { banner } = useSelector((state) => state.banner);

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  console.log("dialogueData", dialogueData)

  const [mongoId, setMongoId] = useState(0);
  const [image, setImage] = useState([]);
  const [type, setType] = useState("");
  const [URL, setURL] = useState("");
  const [tittle, setTittle] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState({
    image: "",
  });
  const dispatch = useDispatch();

  console.log("dialogueData", dialogueData)

  useEffect(() => {
    setError({
      image: "",
      URL: ""
    });
    setURL("");
    setTittle("");
    setImage([]);
  }, []);

  useEffect(() => {
    setMongoId(dialogueData?._id);
    setImagePath(dialogueData?.image ? baseURL + dialogueData?.image : "");
    setURL(dialogueData?.URL);
    setTittle(dialogueData?.title)
    setType(
      options.find((option) => option.value === dialogueData?.bannerType?.toString()) || null
    );
  }, [dialogueData]);

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);

      // Use FileReader for base64 encoding
      const reader = new FileReader();
      reader.onload = () => {
        setImagePath(reader.result);
      };
      reader.readAsDataURL(file);

      // Reset errors
      setError((prevErrors) => ({
        ...prevErrors,
        image: "",
        URL: ""
      }));
    }
  };

  const options = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },

  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      height: "48px",
      border: "1px solid var(--inputBorder--)",
      borderRadius: "6px 0 0 6px",
      padding: "16px",
      fontSize: "14px",
      cursor: "pointer",
      backgroundColor: "#dee2e694",
    }),
  };


  const handleSubmit = (e) => {
    let error = {};

    if (!image || !imagePath) {
      error.image = "Image is required!";
    }

    if (type?.value == 6 && !URL) {
      error.URL = "URL is required!";
    }

    if (!tittle) {
      error.tittle = "Tittle is required!";
    }


    if (Object.keys(error).length > 0) {
      setError({ ...error });
      return;
    }

    const formData = new FormData();
    formData.append("image", image ? image : null);
    formData.append("URL", URL);
    formData.append("bannerType", type?.value);
    formData.append("title", tittle)

    if (mongoId) {
      dispatch(editNewBanner(formData, mongoId))
    } else {

      props.createNewBanner(formData);
    }

    dispatch({ type: CLOSE_DIALOGUE });
  };

  return (
    <div className="mainDialogue fade-in">
      <div
        className="Dialogue"
        style={{ width: "500px", overflow: "auto", maxHeight: "100vh" }}
      >
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Vip Banner Dialog</div>
          <div
            className=""
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <CancelIcon sx={{ fontSize: 35, color: "black", cursor: "pointer" }} />
          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">
            <div>
              <h6 className="fs-6 m-0F">Type</h6>
              <Select
                value={type}
                options={options}
                styles={customStyles}
                onChange={(selectedOption) => {


                  setType(selectedOption); // Ensure value is updated
                }}
              // errorMessage={error.day && error.day}
              />
              {error.type && <div className="errorMessage">{error.type}</div>}
            </div>

            <div className="mt-4">
              <Input
                label={`Tittle`}
                id={`tittle`}
                type={`text`}
                name={`tittle`}
                value={tittle}
                onChange={(e) => {
                  setTittle(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      tittle: `Tittle Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      tittle: "",
                    });
                  }
                }}
                errorMessage={error.tittle && error.tittle}
              />
            </div>
            <div className="mt-2">
              <Input
                label={`URL`}
                id={`level`}
                type={`text`}
                name={`URL`}
                value={URL}
                onChange={(e) => {
                  setURL(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      URL: `URL Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      URL: "",
                    });
                  }
                }}
                errorMessage={error.URL && error.URL}
              />
            </div>


            <div className="col-12">
              <Input
                label={`Vip Image`}
                id={`image`}
                type={`file`}
                onChange={(e) => handleImage(e)}
                errorMessage={error.image && error.image}
              />
              {imagePath && (
                <div className="image-start">
                  <img
                    src={imagePath || Male}
                    alt="banner"
                    draggable="false"
                    width={100}
                    height={100}
                    onError={(e) => e.target.src = Male}
                    className="m-0 cursor rounded"
                    onClick={() => showImg(imagePath)}
                  />
                </div>
              )}
            </div>





          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { createNewBanner })(
  VipBannerDialogue
);
