import axios, { Axios } from "axios";
import { data } from "jquery";
import { setToast } from "../../util/toast";
import { BLOCKED_HOST } from "../FakeHost/fakeHost.type";
import * as ActionType from "./host.type";
import { apiInstanceFetch } from "../../util/api";

export const getHost = (search, start, limit, sort, type) => (dispatch) => {
  apiInstanceFetch
    .get(
      `admin/host?search=${search}&type=1&start=${start}&limit=${limit}&sort=${sort}&sortType=${type}`
    )
    .then((res) => {
      if (res.status) { 
        dispatch({
          type: ActionType.GET_HOST,
          payload: { data: res.host, total: res.total },
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};
export const getPendingHost = (search, start, limit, type) => (dispatch) => {
  apiInstanceFetch
    .get(
      `admin/host/fetchHostRequestByAdmin?search=${search}&type=${type}&start=${start}&limit=${limit}`
    )
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_PENDING_HOST,
          payload: { data: res.host, total: res.total },
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const approvedDisableSwitch = (host, approved) => (dispatch) => {
  axios
    .put(`admin/host/${host._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.APPROVED_HOST,
          payload: { data: res.data.host, id: host._id },
        });

        setToast(
          "success",
          `${host.name} Is ${
            approved === true ? "Reject" : "Approved"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};
export const approvedHostRequest = (hostId, type) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `admin/host/approveOrDecline?hostId=${hostId?.hostId}&type=${hostId?.type}`
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: ActionType.APPROVED_REQ_HOST,
            payload: { data: res.data.host, id: hostId.hostId },
          });
          // setToast("success", `${hostId.hostId} is Approved Successfully!`);
          resolve(res.data);
        } else {
          setToast("error", res.data.message);
          reject(new Error(res.data.message));
        }
      })
      .catch((error) => {
        setToast("error", error.message);
        reject(error);
      });
  });
};
export const approvedHostImageRequest = (hostId, type) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(
        `admin/host/approveRejectImageForHost?hostId=${hostId?.hostId}&type=${hostId?.type}&position=${hostId?.position}`
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: ActionType.APPROVED_REQ_HOST,
            payload: { data: res.data.host, id: hostId.hostId },
          });
          // setToast("success", `${hostId.hostId} is Approved Successfully!`);
          resolve(res.data);
        } else {
          setToast("error", res.data.message);
          reject(new Error(res.data.message));
        }
      })
      .catch((error) => {
        setToast("error", error.message);
        reject(error);
      });
  });
};

export const approvedHostVideoRequest = (hostId, type) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(
        `admin/host/evaluateHostPrivateVideo?hostId=${hostId?.hostId}&type=${hostId?.type}&position=${hostId?.position}`
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: ActionType.APPROVED_REQ_HOST_VIDEO,
            payload: { data: res.data.host, id: hostId.hostId },
          });
          // setToast("success", `${hostId.hostId} is Approved Successfully!`);
          resolve(res.data);
        } else {
          setToast("error", res.data.message);
          reject(new Error(res.data.message));
        }
      })
      .catch((error) => {
        setToast("error", error.message);
        reject(error);
      });
  });
};

export const declineHostRequest = (hostId, type, reason) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `admin/host/approveOrDecline?hostId=${hostId?.hostId}&type=${hostId?.type}&reason=${hostId?.reason}`
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: ActionType.REJECTED_REQ_HOST,
            payload: { data: res.data.host, id: hostId.hostId },
          });
          // setToast("success", `${hostId.hostId} is Declined Successfully!`);
          resolve(res.data);
        } else {
          setToast("error", res.data.message);
          reject(new Error(res.data.message));
        }
      })
      .catch((error) => {
        setToast("error", error.message);
        reject(error);
      });
  });
};

// System Chat Releted :-

export const fetchThumbList =
  (start = 0, limit = 10) =>
  async (dispatch) => {
    dispatch({ type: ActionType.FETCH_THUMBS_REQUEST });

    try {
      const response = await axios.get(
        `/admin/systemChat/loadSystemChatThumbsOfHost?start=${start}&limit=${limit}`
      );

      dispatch({
        type: ActionType.FETCH_THUMBS_SUCCESS,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: ActionType.FETCH_THUMBS_FAILURE,
        payload: error.message,
      });
    }
  };

export const fetchOldSystemChats = (adminId,hostId) => async (dispatch) => {
  dispatch({ type: ActionType.FETCH_OLD_CHATS_REQUEST });

  try {
    const response = await axios.get(
      `/admin/systemChat/retrieveOldSystemChats?adminId=${adminId}&hostId=${hostId}`
    );
    dispatch({
      type: ActionType.FETCH_OLD_CHATS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ActionType.FETCH_OLD_CHATS_FAILURE,
      payload: error.message || "Failed to fetch old system chats",
    });
  }
};

export const blockUnblockSwitch = (host, block) => (dispatch) => {
  axios
    .put(`admin/host/blockUnblock?hostId=${host._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: host?.isFake
            ? ActionType.BLOCKED_HOST
            : ActionType.BLOCKED_HOST,
          payload: { data: res.data.host, id: host._id },
        });
        setToast(
          "success",
          `${host.name} Is ${
            block === true ? "Blocked" : "Unblocked"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

export const createHost = (formData) => (dispatch) => {
  axios
    .post(`admin/host/signup`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ActionType.CREATE_HOST, payload: res.data.host });
        setToast("success", "Host  created Successfully !");
      }
    })
    .catch((error) => console.error(error));
};

// UPDATE SELLER

export const updateHost = (formData, id) => (dispatch) => {
  axios
    .patch(`admin/host/updateProfileByAdmin?hostId=${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_HOST,
          payload: { data: res.data.host, id },
        });

        setToast("success", "Host Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const hostCountry = () => (dispatch) => {
  apiInstanceFetch
    .get(`admin/country`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: ActionType.COUNTRY_HOST, payload: res.country });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

// host Accept

export const hostAccept = (id) => (dispatch) => {
  axios
    .put(`request/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ActionType.ACCEPT_HOST_REQUEST, payload: id });
        setToast("success", "Host Request Accept!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

export const getHostProfile = (id) => (dispatch) => {
  
  apiInstanceFetch
    .get(`client/host/profile?hostId=${id}`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: ActionType.GET_HOST_PROFILE, payload: res.host });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

export const updateHostCoin = (val, id) => (dispatch) => {
  axios
    .post(`admin/host/addLessCoinHost?`, { hostId: id, coin: val })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_HOST_COIN,
          payload: { editHostCoin: res.data.user },
        });
        setToast("success", "HostCoin Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

//  admin/host/addLessCoinHost

export const hostApproved = (host, type) => (dispatch) => {
  axios
    .put(`admin/host/approve?hostId=${host._id}&type=${type}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.APPROVED_HOST,
          payload: { data: res.data.host, id: host._id },
        });
        setToast(
          "success",
          `${host.name} Is ${
            type === "Approved" ? "Approved" : "Rejected"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

// REAL HOST AGENCY DROP DOWN
export const getAgencyDropDown = () => (dispatch) => {
  apiInstanceFetch
    .get(`admin/agency/all`)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_AGENCY_DROPdOWN,
          payload: res.agency,
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

// AgenyWise Host

export const hostAgency = (search, start, limit, agencyId) => (dispatch) => {
  apiInstanceFetch
    .get(
      `admin/host/getHostAgencyWise?&search=${search}&start=${start}&limit=${limit}&agencyId=${agencyId}`
    )
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_HOST_AGENCY_WISE,
          payload: { data: res.host, id: agencyId, total: res.total },
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const getHostHistory =
  (hostId, type, start, limit, startDate, endDate) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/history/hisotryForHost?hostId=${hostId}&type=${type}&start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        if (res.status) {
          dispatch({
            type: ActionType.GET_HOST_HISTORY,
            payload: {
              history: res.history,
              totalCoin: res.totalCoin,
              total: res.total,
            },
          });
        }
      })
      .catch((error) => setToast(error.message));
  };

// host history

export const getHostLiveHistory =
  (id, start, limit, startDate, endDate) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/liveStreamingHistory?hostId=${id}&start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        if (res.status) {
          dispatch({
            type: ActionType.GET_HOST_LIVE_HISTORY,
            payload: {
              history: res.history,
              totalCoin: res.totalCoin,
              total: res.total,
            },
          });
        }
      })
      .catch((error) => setToast("error", error));
  };

export const deleteTopicListHost = (id, type, index) => (dispatch) => {
  axios
    .delete(
      `admin/host/deleteTopic?hostId=${id}&type=${type}&position=${index}`
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_TOPIC_HOST,
          payload: res.data.host,
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const addTopicListHost = (hostId, data) => (dispatch) => {
  axios
    .patch(`admin/host/updateTopic/${hostId}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.ADD_TOPIC_HOST,
          payload: res.data.host,
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
