import React, { useEffect } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//serverPath
import { baseURL } from "../util/config";
import SVGA from "svgaplayerweb";
//sweet alert
import { alert, warning } from "../util/Alert";

//action
import { deleteGift } from "../store/Gift/gift.action";
import { OPEN_DIALOGUE } from "../store/dialogue/dialogue.type";
import { useNavigate } from "react-router-dom";
import Male from "../../assets/images/male.jpg";
import Edit from "../../assets/images/Edit.png";
import Delete from "../../assets/images/delete.png";

const AllGift = (props) => {

  console.log("props", props.data)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleDelete = (giftId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteGift(giftId);
          alert("Deleted!", `Gift has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (props.data?.gift?.length > 0) {
      props.data?.gift?.map((image, index) => {
        if (image.type === 2) {
          var player = new SVGA.Player(`div[attr="${index}"]`);
          var parser = new SVGA.Parser(`div[attr="${index}"]`);
          parser.load(baseURL + image.image, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
          });
        }
      });
    }
  }, [props.data]);
  return (
    <>
      {props.data?.gift?.length > 0 ? (
        props.data?.gift?.map((data, index) => {
          return (
            <>
              <div
                className="col-12 col-sm-6 col-lg-3"
                key={index}
                onClick={() => {
                  localStorage.setItem("CategoryId", data._id);
                  navigate("/admin/giftCategory/gifts");
                }}
              >
                <div class="card contact-card card-bg">
                  <div class="card-body row">
                    <div className="col-xl-6 col-lg-12 col-md-6 text-center">
                      <img
                        src={
                          data.image && baseURL + data.image
                            ? data.image && baseURL + data.image
                            : Male
                        }
                        height={100}
                        width={100}
                        // style={{maxWidth:"100%"}}
                        alt=""
                        onError={(e) => (e.target.src = Male)}
                        className="shadow rounded-circle"
                      />
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 text-center">
                      <div className="contact-card-info m-2 ">

                        <span>
                          {" "}
                          <span className="fw-bolder"> Name : </span>
                          {" "}
                          <span>{data.name}</span>
                        </span>
                      </div>
                      <div className="contact-card-info m-2 ">
                        <span>
                          {" "}
                          <span className="fw-bolder">{data.coin}</span> Coins
                        </span>
                      </div>
                      <div class="contact-card-buttons justify-content-lg-evenly justify-content-md-center   d-flex justify-content-evenly justify-content-xl-center">
                        <img
                          src={Edit}
                          height={30}
                          width={30}
                          alt="Edit"
                          onClick={() => {
                            dispatch({
                              type: OPEN_DIALOGUE,
                              payload: {
                                data: data,
                                type: "Gift",
                              },
                            });

                          }}
                        />

                        <img
                          src={Delete}
                          height={30}
                          width={30}
                          alt="Delete"
                          onClick={() => handleDelete(data._id)}
                          style={{
                            marginLeft: "10px"
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <tr>
          <td colSpan="4" align="center">
            Nothing to show!!
          </td>
        </tr>
      )}
    </>
  );
};

export default connect(null, { deleteGift })(AllGift);
