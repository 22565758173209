import React, { useState } from "react";
import "animate.css";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import {
    createIncome,
    updateIncomePlan,
} from "../../store/income/income.action";
import Cancel from "../../../assets/images/cancel.png";
import CancelIcon from "@mui/icons-material/Cancel";
import Select from "react-select";
import { createDailyReward, updateDailyReward } from "../../store/dailyReward/dailyReward.action";

const CheckInRewardDialogue = (props) => {
    const dispatch = useDispatch();
    const { dialogueData } = useSelector((state) => state.dialogue);
    const { dailyReward } = useSelector((state) => state.dailyReward);
    const [day, setDay] = useState("");
    const [mongoId, setMongoId] = useState(0);
    const [coin, setCoin] = useState("");
    const [error, setError] = useState({
        coin: "",
        day: ""
    });


    useEffect(() => {
        if (dialogueData) {
            setDay(
                options.find((option) => option.value === dialogueData.day.toString()) || null
            );
            setMongoId(dialogueData?._id);
            setCoin(dialogueData?.dailyRewardCoin);
        }
    }, [dialogueData]);



    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            !coin ||
            !day
        ) {
            const error = {};
            if (!coin) error.coin = "Daily Reward Coin is required!";
            if (!day) error.day = "Day is required!";
            return setError({ ...error });
        }
        const data = {
            dailyRewardCoin: coin,
            day: day?.value
        };

        if (mongoId) {
            const updateData = {
                dailyRewardCoinId: dialogueData?._id,
                dailyRewardCoin: coin,
                day: day?.value
            };
            dispatch(updateDailyReward(updateData, mongoId));
        } else {
            dispatch(createDailyReward(data));
        }
        dispatch({ type: CLOSE_DIALOGUE });
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "100%",
            height: "48px",
            border: "1px solid var(--inputBorder--)",
            borderRadius: "6px 0 0 6px",
            padding: "16px",
            fontSize: "14px",
            cursor: "pointer",
            backgroundColor: "#dee2e694",
        }),
    };

    const options = [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" }
    ];

    return (
        <>
            <div className="mainDialogue fade-in">
                <div className="Dialogue">
                    <div className="dialogueHeader">
                        <div className="headerTitle fw-bold">Daily CheckIn Reward</div>
                        <div
                            className="closeDialogue text-center"
                            onClick={() => {
                                dispatch({ type: CLOSE_DIALOGUE });
                            }}
                        >
                            <CancelIcon sx={{ fontSize: 35, color: "black", cursor: "pointer" }} />
                            {/* <img src={cancel} height={30} width={30} alt="Edit" /> */}

                        </div>
                    </div>
                    <div
                        className="dialogueMain"
                    >

                        <div className="row">
                            <div>
                            <h6 className="fs-6 m-0F">Day</h6>

                                <Select
                                    value={day}
                                    options={options}
                                    styles={customStyles}
                                    onChange={(selectedOption) => {
                                        setDay(selectedOption); // Ensure value is updated
                                    }}
                                    isDisabled={dialogueData ? true : false}
                                // errorMessage={error.day && error.day}
                                />
                                {error.day && <div className="errorMessage">{error.day}</div>}
                            </div>
                        </div>
                        <div className="mt-3">
                            <h6 className="fs-6 m-0F">Daily Reward Coin</h6>
                            <Input
                                id={`coin`}
                                type={`text`}
                                value={coin}
                                placeholder={`Enter Daily Reward Coin`}
                                onChange={(e) => {
                                    setCoin(e.target.value);
                                }}
                                errorMessage={error.coin && error.coin}
                            />
                        </div>


                    </div>
                    <div className="dialogueFooter">
                        <div className="dialogueBtn">
                            <Button
                                btnName={`Submit`}
                                btnColor={`btnBlackPrime`}
                                style={{ borderRadius: "5px", width: "80px" }}
                                newClass={`me-2`}
                                onClick={handleSubmit}
                            />
                            <Button
                                btnName={`Close`}
                                btnColor={`bg-danger text-white`}
                                style={{ borderRadius: "5px", width: "80px" }}
                                onClick={() => {
                                    dispatch({ type: CLOSE_DIALOGUE });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { createIncome, updateIncomePlan })(
    CheckInRewardDialogue
);
