import axios from "axios";
import * as ActionType from "./activeTask.type";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";

export const getActiveTask = (search) => (dispatch) => {
    apiInstanceFetch
        .get(`admin/task/fetchTasks`)
        .then((res) => {

            if (res.staus === true) {
                dispatch({
                    type: ActionType.GET_ACTIVE_TASK,
                    payload: res.task,
                });
                // setToast("success", res.message);
            } else {
                setToast("error", res.message);
            }
        })
        .catch((error) => {
            setToast("error", error);
        });
};

export const createActiveTask = (data) => (dispatch) => {
    axios
        .post(`/admin/task/addTask`, data)
        .then((res) => {
            
            if (res.data.status) {
                dispatch({
                    type: ActionType.CREATE_ACTIVE_TASK,
                    payload: res.data.task,
                });
                setToast("success", "Active Task Create successfully");
            } else {
                setToast("error", res.data.message);
            }
        })
        .catch((error) => {
            console.log("error", error.message);
        });
};

// Update Coin PLan
export const updateActiveTask = (data, taskId) => (dispatch) => {
    axios
        .patch(`/admin/task/updateTask`, data)
        .then((res) => {
            
            if (res.data.status) {
                dispatch({
                    type: ActionType.EDIT_ACTIVE_TASK,
                    payload: { data: res.data.task, taskId },
                });
                setToast("success", "Active Task updated successfully");
            } else {
                setToast("error", res.data.message);
            }
        })
        .catch((error) => console.log("error", error.message));
};

export const deleteActiveTask = (taskId) => (dispatch) => {
    axios
        .delete(`admin/task/deleteTask?taskId=${taskId}`)
        .then((res) => {

            if (res.data.status) {
                dispatch({ type: ActionType.DELETE_ACTIVE_TASK, payload: taskId });
                setToast("success", "Active Task deleted successfully!");
            } else {
                setToast("error", res.data.message);
            }
        })
        .catch((error) => console.log(error));
};
