import axios from "axios";
import { setToast } from "../../util/toast";
import {
  CREATE_NEW_BANNER,
  DELETE_BANNER,
  EDIT_NEW_BANNER,
  GET_BANNER,
  VIP_SWITCH,
} from "./banner.type";
import { apiInstanceFetch } from "../../util/api";

export const getBanner = () => (dispatch) => {

  apiInstanceFetch
    .get(`admin/vipBanner/all`)
    .then((res) => {

      if (res.status) {
        dispatch({ type: GET_BANNER, payload: res.banner });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const handleVIPSwitch = (bannerId) => (dispatch) => {

  axios
    .put(`admin/vipBanner/${bannerId}`)
    .then((res) => {

      if (res.data.status) {
        dispatch({ type: VIP_SWITCH, payload: res.data.banner });

        setToast(
          "success",
          `${res.data.banner.isVIP === true
            ? "Vip Banner  Active SuccessFully"
            : "Vip Banner Disable SuccessFully"
          }`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const createNewBanner = (data) => (dispatch) => {
  axios
    .post(`admin/vipBanner`, data)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Banner created successfully!");

        dispatch({ type: CREATE_NEW_BANNER, payload: res.data.banner });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const editNewBanner = (data, id) => (dispatch) => {
  axios
    .patch(`admin/vipBanner/${id}`, data)
    .then((res) => {
      if (res.data.status) {
        
        setToast("success", "Banner Updated successfully!");
        dispatch({ type: EDIT_NEW_BANNER, payload: { data: res.data.banner, id: id } });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const deleteBanner = (bannerId) => (dispatch) => {
  axios
    .delete(`admin/vipBanner/${bannerId}`)
    .then((res) => {

      if (res.data.status) {
        dispatch({ type: DELETE_BANNER, payload: bannerId });
        setToast("success", "Banner deleted successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
