import { useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import { useEffect, useState } from "react";
import Button from "../../../extra/Button";
import { approvedHostImageRequest, getHost } from "../../../store/host/host.action";
import { toast } from "react-toastify";
import Male from "../../../../assets/images/male.jpg";
import CancelIcon from "@mui/icons-material/Cancel"

const HostImage = ({ search, currentPage, rowsPerPage, sort, type }) => {
    const dispatch = useDispatch();
    const { dialogueData } = useSelector(
        (state) => state.dialogue
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const [submitted , setSubmitted] = useState(false);

    const [updatedImageStatus, setUpdatedImageStatus] = useState({ index: null, status: null });

    useEffect(() => {
        if (dialogueData?.image && Array.isArray(dialogueData.image)) {
            setUpdatedImageStatus({ index: null, status: null });
        }
    }, [dialogueData]);

    // Handle dropdown change
    const handleStatusChange = (index, newStatus) => {
        setUpdatedImageStatus({ index, status: newStatus });
    };

    const handleSubmit = () => {
        const data = {
            hostId: dialogueData?._id,
            position: updatedImageStatus?.index,
            type: updatedImageStatus?.status,
        }
        setSubmitted(true);
        dispatch(approvedHostImageRequest(data))
            .then((res) => {
                if (res?.status) {
                    toast.success(res?.message)
                    // dispatch(
                    //     getHost(
                    //         search === "" ? "ALL" : search,
                    //         currentPage,
                    //         rowsPerPage,
                    //         sort,
                    //         type
                    //     )
                    // );
                    dispatch({ type: CLOSE_DIALOGUE })
                } else {
                    toast.error(res?.message)
                }
            })
    };
    return (
        <>
            <div className="mainDialogue fade-in" style={{ zIndex: "9999999" }}>
                <div
                    className="Dialogue"
                    style={{ overflow: "auto", maxHeight: "100vh", width: "800px" }}
                >
                    <div className="dialogueHeader">
                        <div className="headerTitle fw-bold">Host Image Dialog</div>
                        <div
                            className="closeDialogue text-center"
                            onClick={() => {
                                dispatch({ type: CLOSE_DIALOGUE });
                            }}
                        >
                            <CancelIcon sx={{ fontSize: 35, color: "black", cursor: "pointer" }} />

                        </div>
                    </div>
                    <div
                        className="dialogueMain bg-white"
                        style={{ overflow: "auto", borderRadius: "10px", padding: "16px" }}
                    >
                        {dialogueData?.image && Array.isArray(dialogueData.image) && (
                            <div className="imageGrid">
                                {dialogueData?.image?.map((image, index) => (
                                    <div key={index} className="imageContainer">
                                        <img
                                            src={image?.url || Male} 
                                            alt={`Image ${index}`}
                                            className="imageItem"
                                            style={{ objectFit: "cover" }}
                                        />
                                        {image?.verificationStatus === 2 || image?.verificationStatus === 3 ? (
                                            <span className="statusLabel">
                                                <input
                                                    type="text"
                                                    disabled
                                                    className={`form-control form-input`}
                                                    value={image?.verificationStatus === 2 ? "Verified" : "Rejected"}
                                                />

                                            </span>
                                        ) : (
                                            <select
                                                className="form-select"
                                                aria-label="Image status select"
                                                value={updatedImageStatus.index === index ? updatedImageStatus.status : image.verificationStatus}
                                                onChange={(e) =>
                                                    handleStatusChange(index, parseInt(e.target.value))
                                                }
                                            >
                                                <option value="Select image status" disabled>
                                                    Select Image Status
                                                </option>
                                                <option value="1">Pending</option>
                                                <option value="2">Verified</option>
                                                <option value="3">Rejected</option>
                                            </select>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="dialogueFooter">
                        <div className="dialogueBtn">
                            <Button
                                btnName={`Submit`}
                                btnColor={`btnBlackPrime text-white`}
                                style={{ borderRadius: "5px", width: "80px" }}
                                newClass={`me-2`}
                                onClick={handleSubmit}
                            />
                            <Button
                                btnName={`Close`}
                                btnColor={`bg-danger text-white`}
                                style={{ borderRadius: "5px", width: "80px" }}
                                onClick={() => {
                                    dispatch({ type: CLOSE_DIALOGUE });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HostImage;