import { Link, useNavigate } from "react-router-dom";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUser, blockSwitch } from "../../store/user/user.action";
import ToggleSwitch from "../../extra/ToggleSwitch";
import dayjs from "dayjs";
import Pagination from "../../extra/Pagination";
import MailIcon from "@mui/icons-material/Mail";
import Searching from "../../extra/Searching";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import UserInfo from "./UserInfo";
import UserHistory from "./UserHistory/UserHistory";
import Male from "../../../assets/images/boy.jpg";
import { getMultiAppSetting } from "../../store/setting/setting.action";
import Info from "../../../assets/images/Info.png";
import History from "../../../assets/images/History.png";

const User = (props) => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { multiAppSetting } = useSelector((state) => state.setting);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("all");
  const { user, totalUser } = useSelector((state) => state.user);
  const [sort, setSort] = useState("createdAt");
  const [type, setType] = useState(0);
  const [appType, setAppType] = useState("ALL");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      getUser(
        search == "" ? "all" : search,
        currentPage + 1,
        rowsPerPage,
        sort,
        type,
        appType
      )
    );
  }, [dispatch, search, currentPage, rowsPerPage, sort, type, appType]);

  useEffect(() => {
    dispatch(getMultiAppSetting());
  }, [dispatch]);

  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  useEffect(() => {
    setData(user);
  }, [user]);
  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(0);
  };
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      // $(this).attr("src", Male);
    });
  });

  const handleClick = (userDetails) => {
    props.blockSwitch(
      userDetails,
      userDetails?.isBlock === true ? false : true
    );
  };
  // const handleInfo = (id) => {
  //   navigate("/admin/userInfo", {
  //     state: id,
  //   });
  // };

  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
    localStorage.setItem("userInfoGo", JSON.stringify(dialogueData_));
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      // Cell: ({ index }) => <span>{index + 1}</span>,
      Cell: ({ index }) => (
        <span>{(index) + 1}</span>
      ),
    },
    {
      Header: "Author",
      body: "image",
      Cell: ({ row }) => (
        <div>
          <div
            className="d-flex px-2 py-1 pointer"
            // onClick={() => handleInfo(row?._id)}
            onClick={() => handleProfile(row, "userInfo")}
            style={{ cursor: "pointer" }}
          >
            <div>
              <img
                src={row?.image}
                alt=""
                draggable="false"
                style={{ borderRadius: "25px" }}
                height={50}
                width={50}
              />
              {/* {row.isOnline && (
                <span
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "green",
                    bottom: "-4px",
                    right: "3px",
                    border: "3px solid #ffff",
                  }}
                  className="d-block position-absolute"
                ></span>
              )} */}
            </div>
            <div className="d-flex flex-column justify-content-center text-start ms-3">
              <b className="mb-0 text-sm text-capitalize ">{row?.name}</b>
              <div>
                <div className="d-flex">
                  <MailIcon
                    style={{ color: "rgb(225 78 132)", fontSize: "16px" }}
                  />
                  <p
                    className="text-xs text-secondary mb-0  text-capitalize"
                    style={{ fontSize: "12px" }}
                  >
                    {row?.email}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: "Unique Id",
      body: "uniqueId",
      Cell: ({ row }) => <span>{row.uniqueId ? row.uniqueId : "-"}</span>,
    },
    {
      Header: "App Name",
      body: "packageName.appName",
    },
    {
      Header: "Identity",
      body: "identity",
      Cell: ({ row }) => <span>{row.identity ? row.identity : "-"}</span>,
    },

    { Header: "Coin", body: "coin", sorting: { type: "server" } },
    {
      Header: "Purchased Coin",
      body: "purchageCoin",
      sorting: { type: "server" },
    },
    { Header: "Gender", body: "gender" },
    { Header: "Country", body: "country" },
    {
      Header: "Block",
      body: "isBlock",
      Cell: ({ row }) => (
        <ToggleSwitch value={row.isBlock} onClick={() => handleClick(row)} />
      ),
    },
    {
      Header: "Created Date",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Last Login",
      body: "lastLogin",
      Cell: ({ row }) => (
        <span className="text-nowrap">
          {dayjs(row.lastLogin).format("DD MMM YYYY")}
        </span>
      ),
    },
    {
      Header: "Status",
      body: "",
      Cell: ({ row }) =>
        row?.isOnline === true && row?.isBusy === true ? (
          <div
            className="bgPink py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Busy
          </div>
        ) : row?.isOnline === true ? (
          <div
            className="bg-success py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Online
          </div>
        ) : (
          <div
            className="bg-danger py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Offline
          </div>
        ),
    },
    {
      Header: "Info",
      body: "",
      Cell: ({ row }) => (
        <img
        src={Info}
        alt=""
        draggable="false"
   
        onClick={() => handleProfile(row, "userInfo")}
        height={30}
        width={30}
      />
      ),
    },
    {
      Header: "History",
      body: "",
      Cell: ({ row }) => (
        <img
        src={History}
        alt=""
        draggable="false"
        onClick={() => handleProfile(row, "userHistory")}
        height={30}
        width={30}
      />
      ),
    },

    // add more columns as needed
  ];

  const handleChildValue = (value) => {
    setType(type === -1 ? 1 : -1);
    setSort(value);
  };
  return (
    <>
      <div className="mainUserTable">
        {dialogue && dialogueType == "userInfo" && (
          <div className="userTable">
            <UserInfo />
          </div>
        )}
        {dialogue && dialogueType === "userHistory" && (
          <div className="userTable">
            <UserHistory />
          </div>
        )}
        <div
          className="userTable"
          style={{
            display: `${
              dialogueType === "userInfo" || dialogueType === "userHistory"
                ? "none"
                : "block"
            }`,
          }}
        >
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`User`} />
            </div>
            <div className="d-flex justify-content-between col-md-12 mt-3">
              <div class="btn-group mt-2 mb-2 mx-2">
                <button
                  type="button"
                  class="btn btn-success btn-pill dropdown-toggle text-white"
                  data-bs-toggle="dropdown"
                >
                  {/* {appType ? (
                          <span class="caret">{appType}</span>
                        ) : (
                          <span class="caret text-capitalize">Country</span>
                        )} */}
                  App Name
                </button>
                <ul class="dropdown-menu my-1">
                  {multiAppSetting?.map((res) => {
                    return (
                      <li style={{ cursor: "pointer" }}>
                        <a
                          className="dropdown-item"
                          href={() => false}
                          onClick={() => {
                            setAppType(res?.appName);
                            // sessionStorage.setItem(
                            //   "countryTypeHost",
                            //   res.appName
                            // );
                          }}
                        >
                          {res?.appName}
                        </a>
                      </li>
                    );
                  })}

                  <li style={{ cursor: "pointer" }}>
                    <a
                      className="dropdown-item"
                      href={() => false}
                      onClick={() => {
                        setAppType("ALL");
                        sessionStorage.setItem("countryTypeHost", "");
                      }}
                    >
                      Reset All
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <Searching
                  type={"server"}
                  data={user}
                  setData={setData}
                  setSearchData={setSearch}
                  onFilterData={handleFilterData}
                  serverSearching={handleFilterData}
                  button={true}
                  column={mapData}
                  placeholder={"Searching User..."}
                />
              </div>
            </div>
          </div>
          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
                onChildValue={handleChildValue}
              />
            </div>
            <div className="paginationFooter">
              <Pagination
                component="div"
                count={user?.length}
                serverPage={currentPage}
                type={"server"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={totalUser}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
          <div className="userFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getUser, blockSwitch, getMultiAppSetting })(
  User
);
