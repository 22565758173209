import React, { useState } from "react";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Chip, ListItem, Paper } from "@mui/material";
import Input from "./Input";
import Button from "./Button";
import AddIcon from "@mui/icons-material/Add";

export default function TopicListForHost(props) {
  const {
    label,
    handleAddData,
    handleDeleteChip,
    setAddChipData,
    addChipData,
    addChip,
    disabled
  } = props;

  return (
    <div className="topic-list-chip-host">
      <div className="row">
        <div className="col-9 p-0 topicFontSize">
          <Input
            label={label}
            id={`label`}
            type={`text`}
            value={addChipData}
            placeholder={`Add Your Topic ....!`}
            onChange={(e) => {
              setAddChipData(e.target.value);
            }}
            style={{ borderRadius: "20px" }}
          />
        </div>
        <div className="col-1 my-auto topicBTN">
        
          <button className="add-detail-btn text-white p-2"
          style={{
            border : "none"
          }}
          >
            +
          </button>

        </div>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            p: 0.5,
            marginTop: "20px",
            marginBottom: "20px",
          }}
          component="div"
          style={{
            overflow: "scroll",
            maxHeight: "100px",
            minHeight: "100px",
            paddingTop: "10px",
            backgroundColor: "white",
            borderRadius: "12px",
            paddingLeft: 0,
            paddingRight: 0,
            width: "90%"

          }}
        >
          {addChip?.length > 0 && addChip?.map((data, index) => {
            let icon;

            if (data.label === "React") {
              icon = <TagFacesIcon />;
            }
            return (
              <Chip
                variant="outlined"
                color="success"
                icon={icon}
                label={data}
                key={index}
                onDelete={() => handleDeleteChip(index)}
              />
            );
          })}
        </Paper>
      </div>
    </div>
  );
}
