import React, { useState } from "react";
import "animate.css";
import Input from "../../../extra/Input";
import Button from "../../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CLOSE_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import {
  createNewVIPPlan,
  editVIPPlan,
} from "../../../store/vipPlan/action";
import CancelIcon from "@mui/icons-material/Cancel"
import Cancel from "../../../../assets/images/cancel.png"


const VipPlanDialogue = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [mongoId, setMongoId] = useState("");
  const [validity, setValidity] = useState("");
  const [validityType, setValidityType] = useState("");
  const [dollar, setDollar] = useState("");
  const [rupee, setRupee] = useState("");
  const [tag, setTag] = useState("");
  const [productKey, setProductKey] = useState("");
  const [name, setName] = useState("");

  const [errors, setError] = useState({
    validity: "",
    dollar: "",
    rupee: "",
    productKey: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData._id);
      setValidity(dialogueData?.validity);
      setValidityType(dialogueData?.validityType);
      setDollar(dialogueData.dollar);
      setRupee(dialogueData.rupee);
      setTag(dialogueData.tag);
      setProductKey(dialogueData.productKey);
      setName(dialogueData.name);
    }
  }, [dialogueData]);
  useEffect(
    () => () => {
      setError({
        validity: "",
        dollar: "",
        rupee: "",
        productKey: "",
        name: "",
      });
      setMongoId("");
      setValidity("");
      setValidityType("");
      setTag("");
      setDollar("");
      setRupee("");
      setProductKey("");
      setName("");
    },
    []
  );
  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+(\.\d{1,2})?$/.test(val);
    return validNumber;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validity || !dollar || !rupee || !productKey) {
      const errors = {};

      if (!validity) errors.validity = "Validity is required!";
      if (!dollar) errors.dollar = "Dollar is required!";
      if (dollar < 0) errors.dollar = "Invalid Dollar!";
      if (!rupee) errors.rupee = "Rupee is required!";
      if (rupee < 0) errors.rupee = "Invalid Rupee!";
      if (!productKey) errors.productKey = "Product Key is required!";

      return setError({ ...errors });
    }

    const validityValid = isNumeric(validity);
    if (!validityValid) {
      return setError({ ...errors, validity: "Invalid Validity!!" });
    }
    const dollarValid = isNumeric(dollar);
    if (!dollarValid) {
      return setError({ ...errors, dollar: "Invalid Dollar!!" });
    }
    const rupeeValid = isNumeric(rupee);
    if (!rupeeValid) {
      return setError({ ...errors, rupee: "Invalid Rupee!!" });
    }

    const data = {
      validity,
      validityType: validityType ? validityType : "day",
      dollar,
      rupee,
      tag,
      productKey,
      name,
    };
    // if (!hasPermission) return permissionError();
    if (mongoId) {
      props.editVIPPlan(mongoId, data);
    } else {
      props.createNewVIPPlan(data);
    }
    dispatch({type : CLOSE_DIALOGUE})
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Create Vip Plan</div>
            <div
              className="boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <CancelIcon sx={{ fontSize: 35, color: "black", cursor: "pointer" }} />
            </div>
          </div>
          <div className="dialogueMain" style={{overflow:'auto',maxHeight:"100vh"}}>
            <div className="row">
              <div className="col-6">
                <Input
                  label={`Name`}
                  id={`name`}
                  type={`text`}
                  value={name}
                  errorMessage={errors.name && errors.name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...errors,
                        name: `Coin Is Required`,
                      });
                    } else {
                      return setError({
                        ...errors,
                        name: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Tag`}
                  id={`tag`}
                  type={`text`}
                  value={tag}
                  placeholder={`Enter Tag`}
                  
                  onChange={(e) => 
                    setTag(e.target.value)
                   
                  }
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Dollar`}
                  id={`dollar`}
                  type={`number`}
                  value={dollar}
                  errorMessage={errors.dollar && errors.dollar}
                  onChange={(e) => {
                    setDollar(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...errors,
                        dollar: `Dollar is required`,
                      });
                    } else {
                      return setError({
                        ...errors,
                        dollar: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Rupee`}
                  id={`rupee`}
                  type={`number`}
                  value={rupee}
                  errorMessage={errors.rupee && errors.rupee}
                  onChange={(e) => {
                    setRupee(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...errors,
                        rupee: `Rupee coin is required !`,
                      });
                    } else {
                      return setError({
                        ...errors,
                        rupee: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Validity`}
                  id={`validity`}
                  type={`number`}
                  value={validity}
                  placeholder={`1`}
                  errorMessage={errors?.validity && errors?.validity}
                  onChange={(e) => {
                    setValidity(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...errors,
                        validity: `Validity Is Required`,
                      });
                    } else {
                      return setError({
                        ...errors,
                        validity: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
              <div className="form-group">
                      <label className="text-gray mb-2">Validity Type</label>
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        value={validityType}
                        onChange={(e) => {
                          setValidityType(e.target.value);
                        }}
                      >
                        <option value="days" selected>
                          Day
                        </option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                      </select>
                    </div>
              </div>
            
              <div className="col-12">
                <Input
                  label={`Product Key`}
                  id={`productKey`}
                  type={`text`}
                  value={productKey}
                  placeholder={`Enter Product Key`}
                  errorMessage={errors.productKey && errors.productKey}
                  onChange={(e) => {
                    setProductKey(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...errors,
                        productKey: `Product Key Is Required`,
                      });
                    } else {
                      return setError({
                        ...errors,
                        productKey: "",
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default connect(null, { createNewVIPPlan, editVIPPlan })(
  VipPlanDialogue
);
