import React, { useState } from "react";
import "animate.css";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import {
    createIncome,
    updateIncomePlan,
} from "../../store/income/income.action";
import Cancel from "../../../assets/images/cancel.png";
import CancelIcon from "@mui/icons-material/Cancel";
import Select from "react-select";
import { createQualityLevel, updateQualityLevel } from "../../store/qualityLevel/qualitylevel.action";
import { createActiveTask, updateActiveTask } from "../../store/activeTask/activeTask.action";

const ActiveTaskDialogue = (props) => {
    const dispatch = useDispatch();
    const { dialogueData } = useSelector((state) => state.dialogue);
    const [mongoId, setMongoId] = useState(0);
    // const [matchCallPrice, setMatchCallPrice] = useState(0);
    const [type, setType] = useState("");
    const [taskName, setTaskName] = useState("");
    const [targetedValue, setTargetedValue] = useState("");
    const [reward, setReward] = useState("");
    const [description, setDescription] = useState("");
    const [callDuration, setCallDuration] = useState("");
    const [error, setError] = useState({
        // matchCallPrice: "",
        taskName: "",
        targetedValue: "",
        reward: "",
        description: "",
        callDuration: ""
    });

    console.log("dialogueData", dialogueData)
    console.log("type", type)


    const options = [
        { value: "2", label: "Call" },
        { value: "3", label: "Gift" },
    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "100%",
            height: "48px",
            border: "1px solid var(--inputBorder--)",
            borderRadius: "6px 0 0 6px",
            padding: "16px",
            fontSize: "14px",
            cursor: "pointer",
            backgroundColor: "#dee2e694",
        }),
    };

    useEffect(() => {
        setMongoId(dialogueData?._id);
        setTaskName(dialogueData?.taskName)
        setTargetedValue(dialogueData?.targetValue);
        setReward(dialogueData?.reward)
        setDescription(dialogueData?.description)
        setCallDuration(dialogueData?.thresholds?.callDuration)
        const selectedOption = options.find(
            (option) => option.value === dialogueData?.taskType?.toString()
        );
        console.log("selectedOption" , selectedOption)

        
        setType(selectedOption);
    }, [dialogueData]);

    console.log("error" , error)


    const handleSubmit = (e) => {
        e.preventDefault();
    
        const error = {};
    
        // Validate required fields
        if (!taskName) {
            error.taskName = "Task Name is required!";
        }
    
        if (!type) {
            error.type = "Type is required!";
        }
    
        if (!targetedValue) {
            error.targetedValue = "Targeted Value is Required!";
        }
    
        if (!reward) {
            error.reward = "Reward is Required!";
        }
    
        if (!description) {
            error.description = "Description is Required!";
        }
    
        // Validate callDuration only if type.value is not "3"
        if (type?.value !== "3" && !callDuration) {
            error.callDuration = "Call Duration is Required!";
        }
    
        // If there are errors, set them and stop submission
        if (Object.keys(error).length > 0) {
            return setError({ ...error });
        }
    
        // Prepare data for submission
        const data = {
            taskName: taskName.trim(),
            targetValue: parseInt(targetedValue, 10),
            description: description.trim(),
            reward: parseInt(reward, 10),
            callDuration: type?.value === "3" ? "" : parseInt(callDuration, 10),
            taskType: type?.value,
        };
    
        // Dispatch the appropriate action
        if (mongoId) {
            const updateData = {
                ...data,
                taskId: mongoId,
            };
            dispatch(updateActiveTask(updateData, mongoId));
        } else {
            dispatch(createActiveTask(data));
        }
    
        // Close the dialog after successful submission
        dispatch({ type: CLOSE_DIALOGUE });
    };
    

    return (
        <>
            <div className="mainDialogue fade-in">
                <div className="Dialogue">
                    <div className="dialogueHeader">
                        <div className="headerTitle fw-bold">Active Task</div>
                        <div
                            className="closeDialogue text-center"
                            onClick={() => {
                                dispatch({ type: CLOSE_DIALOGUE });
                            }}
                        >
                            <CancelIcon sx={{ fontSize: 35, color: "black", cursor: "pointer" }} />
                            {/* <img src={cancel} height={30} width={30} alt="Edit" /> */}

                        </div>
                    </div>
                    <div
                        className="dialogueMain"
                        style={{ overflow: "auto", maxHeight: "100vh" }}
                    >
                        <div className="row">
                        <div className="col-6">
                            <h6 className="fs-6 m-0F">Type</h6>

                                <Select
                                    value={type}
                                    options={options}
                                    styles={customStyles}
                                    onChange={(selectedOption) => {
                                        setType(selectedOption); // Ensure value is updated
                                    }}
                                    isDisabled={dialogueData ? true : false}
                                // errorMessage={error.day && error.day}
                                />
                                {error.type && <div className="errorMessage">{error.type}</div>}
                            </div>


                            <div className="col-6">
                                <Input
                                    label={`Task Name`}
                                    id={`taskName`}
                                    type={`text`}
                                    value={taskName}
                                    placeholder={`Enter Task Name`}
                                    onChange={(e) => {
                                        setTaskName(e.target.value);

                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                taskName: `Task Name Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                taskName: "",
                                            });
                                        }
                                    }}
                                    errorMessage={error.taskName && error.taskName}
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="description" className="form-label" style={{
                                    fontWeight: "bold"
                                }}>
                                    Description
                                </label>
                                <textarea
                                    id="description"
                                    className={`form-control ${error.description ? "is-invalid" : ""}`}
                                    placeholder="Enter Description"
                                    style={{
                                        border: "1px solid #cbd5e1"
                                    }}
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                        if (!e.target.value) {
                                            setError({
                                                ...error,
                                                description: "Description Is Required",
                                            });
                                        } else {
                                            setError({
                                                ...error,
                                                description: "",
                                            });
                                        }
                                    }}
                                />
                                {error.description && (
                                    <div className="errorMessage">{error.description}</div>
                                )}
                            </div>

                            {
                                type?.value !== '3'  &&

                                <div className="col-6 mt-2">
                                <Input
                                    label={`Call Duration (Second)`}
                                    id={`callDuration`}
                                    placeholder={`Enter Call Duration`}
                                    type={`number`}
                                    value={callDuration}
                                    errorMessage={
                                        error.callDuration && error.callDuration
                                    }
                                    onChange={(e) => {
                                        setCallDuration(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                callDuration: `Call Duration Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                callDuration: "",
                                            });
                                        }
                                    }}
                                />
                            </div>
                            }
                            <div className="col-6">
                                <Input
                                    label={`Reward`}
                                    id={`reward`}
                                    placeholder={`Enter Reward`}
                                    type={`number`}
                                    value={reward}
                                    errorMessage={
                                        error.reward && error.reward
                                    }
                                    onChange={(e) => {
                                        setReward(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                reward: `Reward Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                reward: "",
                                            });
                                        }
                                    }}
                                />
                            </div>


                            <div className="col-6"
                            style={{
                                marginTop : dialogueData?.taskType === 3 ?  "10px" : "0px"
                            }}
                            >
                                <Input
                                    label={`Target Value`}
                                    id={`targetedValue`}
                                    type={`number`}
                                    value={targetedValue}
                                    placeholder={`Enter Targeted Value`}
                                    errorMessage={error.targetedValue && error.targetedValue}
                                    onChange={(e) => {
                                        setTargetedValue(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                targetedValue: `Targeted Value Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                targetedValue: "",
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="dialogueFooter">
                            <div className="dialogueBtn">
                                <Button
                                    btnName={`Submit`}
                                    btnColor={`btnBlackPrime`}
                                    style={{ borderRadius: "5px", width: "80px" }}
                                    newClass={`me-2`}
                                    onClick={handleSubmit}
                                />
                                <Button
                                    btnName={`Close`}
                                    btnColor={`bg-danger text-white`}
                                    style={{ borderRadius: "5px", width: "80px" }}
                                    onClick={() => {
                                        dispatch({ type: CLOSE_DIALOGUE });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { createIncome, updateIncomePlan })(
    ActiveTaskDialogue
);
