import EditIcon from "../../../assets/images/Edit.png";
import AddIcon from "@mui/icons-material/Add";
import {
  activeWithdrawMethod,
  deleteWithdrawMethod,
  getSetting,
  getWithdrawSetting,
  updateSetting,
} from "../../store/setting/setting.action";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddWithdrawDialogue from "./AddWithdrawDialog";
// import { openDialog } from "../../store/dialogue/dialogue.reducer";
import { baseURL } from "../../util/config";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import Table from "../../extra/Table";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Pagination from "../../extra/Pagination";
import { warning } from "../../util/Alert";
import Delete from "../../../assets/images/delete.png";
import EditInfo from "../../../assets/images/Edit.png";
import noImage from "../../../assets/images/noimage.png";

const WithdrawSetting = () => {
  const { withdrawSetting, withdraw, setting } = useSelector(
    (state) => state.setting
  );



  const { dialogueType } = useSelector(
    (state) => state.dialogue
  );

  console.log("withdrawSetting", withdraw)
  console.log("setting", setting?._id)

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [actionPagination, setActionPagination] = useState("delete");
  const [selectCheckData, setSelectCheckData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [minWithdrawalRequestedCoin, setMinWithdrawalRequestedCoin] =
    useState();
  const [minCoinsForPayout, setMinCoinForPayout] = useState();
  const [error, setError] = useState({
    minWithdrawalRequestedCoin: "",
  });

  useEffect(() => {
    let payload = {};
    dispatch(getWithdrawSetting());
    dispatch(getSetting(payload));
  }, [dispatch]);

  useEffect(() => {
    setMinWithdrawalRequestedCoin(setting?.minWithdrawableCoin);
    setMinCoinForPayout(setting?.minCoinsForPayout)
  }, [setting]);

  useEffect(() => {
    setData(withdraw);
  }, [withdraw]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleEdit = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: { type: type, data: row },
    });
    // dispatch(openDialog({ type: type, data: row }));
  };

  const handleNewOpen = () => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: { type: "withdraw" },
    });
  };

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          dispatch(deleteWithdrawMethod(id))
          // props.deleteBanner(id);
        }
      })
      .catch((err) => console.log(err));
  };

  const mapData = [
    {
      Header: "No",
      body: "name",
      Cell: ({ index }) => <span>{(page - 1) * rowsPerPage + index + 1}</span>,
    },

    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => {
        const regex = /https?:\/\/[^\\]*\\.*\.(jpg|png|jpeg|gif)/;
        const isImage = regex.test(row?.image);

        console.log("isImage" , isImage)
        return (
          <img
            src={isImage ? baseURL + row?.image : noImage}
            width="96px"
            height="auto"
            style={{ objectFit: "cover" }}
            alt="Row Image"
          />
        );
      },
    },

    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => <span className="text-capitalize">{row?.name}</span>,
    },
    {
      Header: "Details",
      body: "details",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          <ul>
            {row?.details?.map((detail, index) => (
              <li>{`${detail}`}</li>
            ))}
          </ul>
        </span>
      ),
    },
    {
      Header: "Created date",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD MMMM YYYY") : ""}
        </span>
      ),
    },
    {
      Header: "Is active",
      body: "isActive",
      Cell: ({ row }) => (
        console.log("isActive", row),
        <ToggleSwitch
          value={row?.isActive}
          onClick={() => handleIsActive(row)}
        />
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <div className="action-button">
          <img
            src={EditInfo}
            height={30}
            width={30}
            alt="Edit"
            onClick={() => handleEdit(row, "withdraw")}
          />
        </div>
      ),
    },


    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <img src={Delete} onClick={() => handleDelete(row?._id)} height={30} width={30} alt="Delete" />
      ),
    },

  ];


  const handleIsActive = (row) => {
    const id = row?._id;
    dispatch(activeWithdrawMethod(id));
  };

  const handleSubmit = () => {
    const minWithdrawalRequestedCoinValue = parseInt(
      minWithdrawalRequestedCoin
    );

    if (minWithdrawalRequestedCoinValue <= 0) {
      let error = {};

      if (minWithdrawalRequestedCoin === "")
        if (minWithdrawalRequestedCoinValue <= 0)
          error.minWithdrawalRequestedCoin = "Amount Invalid !";

      return setError({ ...error });
    } else {
      let data = {
        minWithdrawableCoin: parseInt(minWithdrawalRequestedCoin),
        minCoinsForPayout: parseInt(minCoinsForPayout)
      };

      let payload = {
        data: data,
      };
      dispatch(updateSetting(payload, setting?._id));
    }
  };

  return (
    <>
      {dialogueType === "withdraw" && <AddWithdrawDialogue />}
      <div className="userPage withdrawal-page p-0">
        <div className="dashboardHeader primeHeader mb-3 p-0"></div>
        <div className="row" style={{
          marginLeft: "8px"
        }}>
          <div className="col-6">
            <div className="withdrawal-box payment-setting settingBox">
              <div className="row align-items-center p-2">
                <div className="col-12 col-sm-6"
                  style={{
                    fontSize: "20px",
                    paddingLeft: "2px",
                    fontWeight: 500
                  }}
                >
                  Minimum Withdrawal Limit
                </div>
                <div className="col-12 col-sm-6 sm-m-0 d-flex justify-content-end p-0">
                  <Button
                    btnName={`Submit`}
                    // btnIcon={`fa-solid fa-circle-arrow-right ms-2 `}
                    btnColor={`btnBlackPrime`}
                    style={{
                      width: "80px",
                      borderRadius: "6px",
                      display: "flex",
                      borderRadius: "6px",
                      flexDirection: "row-reverse",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={handleSubmit}
                  />
                  {/* <Button
                    btnName={"Submit"}
                    type={"button"}
                    onClick={handleSubmit}
                    newClass={"submit-btn"}
                    style={{
                      borderRadius: "0.5rem",
                      width: "88px",
                      marginLeft: "10px",
                    }}
                  /> */}
                </div>
              </div>
              <div className="row ">
                <div className="col-12 withdrawal-input mt-1">
                  <Input
                    label={"Minimum Withdrawal Coin"}
                    name={"minWithdrawalRequestedCoin"}
                    type={"number"}
                    value={minWithdrawalRequestedCoin}
                    errorMessage={
                      error.minWithdrawalRequestedCoin &&
                      error.minWithdrawalRequestedCoin
                    }
                    placeholder={"Enter Amount"}
                    onChange={(e) => {
                      setMinWithdrawalRequestedCoin(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          minWithdrawalRequestedCoin: `Amount Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          minWithdrawalRequestedCoin: "",
                        });
                      }
                    }}
                  />
                  <h6>
                    Minimum withdrawal Coin Amount the users can request
                  </h6>
                </div>

                <div className="col-12 withdrawal-input mt-1">
                  <Input
                    label={"Minimum Coins For Payout"}
                    name={"minCoinsForPayouts"}
                    type={"number"}
                    value={minCoinsForPayout}
                    errorMessage={
                      error.minCoinsForPayout &&
                      error.minCoinsForPayout
                    }
                    placeholder={"Enter Minimum Coin For Payouts"}
                    onChange={(e) => {
                      setMinCoinForPayout(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          minCoinsForPayout: `Coins For Payout Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          minCoinsForPayout: "",
                        });
                      }
                    }}
                  />
                  <h6>
                    Minimum Coins For Payout the users can request
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="payment-setting-box user-table mt-3">
          <div className="row align-items-center mb-2 p-3 ml-1">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
              <h5 className="mb-0">Withdraw Payment Method</h5>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 new-fake-btn d-flex justify-content-end mt-3 m-sm-0">
              {/* <Button
                btnIcon={<AddIcon />}
                newClass={"rounded"}
                btnName={"New"}
                onClick={() => handleOpenNew("withdraw")}
              /> */}

              <Button
                newClass={` border bgsuccess text-white`}
                btnColor={`btnthemePrime`}
                btnIcon={`fas fa-plus fa-md`}
                btnName={`New`}
                onClick={() => handleNewOpen()}
                style={{ borderRadius: "7px" }}
              />

            </div>
          </div>
          <div className="userMain">
            <div className="tableMain mt-3 m-0">
              <Table
                data={withdraw}
                mapData={mapData}
                type={"client"}
              />
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawSetting;
