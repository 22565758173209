import { key } from "../../util/config";
import { SetDevKey, setToken } from "../../util/setAuth";
import * as ActionType from "./qualitylevel.type";


const initialState = {
    qualityLevel: [],
    total: null,
};

export const QualityReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.GET_QUALITY_LEVEL:
            return {
                ...state,
                qualityLevel: action.payload,
            };
        case ActionType.CREATE_QUALITY_LEVEL:
            let data = [...state.qualityLevel];
            data.unshift(action.payload);
            return {
                ...state,
                qualityLevel: data,
            };
        case ActionType.EDIT_QUALITY_LEVEL:
            return {
                ...state,
                qualityLevel: state.qualityLevel.map((data) =>
                    data._id === action.payload.id ? action.payload.data : data
                ),
            };
        default:
            return state;
    }
};
