import { combineReducers } from "redux";
import { dialogueReducer } from "./dialogue/dialogue.reducer";
import { adminReducer } from "./admin/admin.reducer";
import { coinPlanReducer } from "./coinPlan/coinPlan.reducer";
import { settingReducer } from "./setting/setting.reducer";
import { dashboardReducer } from "./dashboard/dashboard.reducer";
import { userReducer } from "./user/user.reducer";
import { agencyReducer } from "./agency/agency.reducer";
import { hostReducer } from "./host/host.reducer";
import { fakeHostReducer } from "./FakeHost/fakeHost.reducer";
import giftReducer from "./Gift/gift.reducer";
import { giftCategoryReducer } from "./giftCategory/reducer";
import { flashCoinReducer } from "./flashCoin/FlashCoin.reducer";
import { feedBackReducer } from "./feedback/feedback.reducer";
import vipPlanReducer from "./vipPlan/reducer";
import { commissionReducer } from "./commission/commission.reducer";
import { settlementReducer } from "./settlement/settlement.reducer";
import { notificationReducer } from "./notification/notification.reducer";
import { reportReducer } from "./report/report.reducer";
import topicReducer from "./topic/topic.reducer";
import { fakeStoryReducer } from "./fakeStory/fakeStory.reducer";
import bannerReducer from "./vipBanner/banner.reducer";
import levelReducer from "./VipLevel/level.reducer"
import { flashVipReducer } from "./flashVip/FlashVip.reducer";
import { IncomeReducer } from "./income/income.reducer";
import { RewardReducer } from "./reward/reward.reducer";
import { DailyCheckInRewardReducer } from "./dailyReward/dailyReward.reducer";
import { QualityReducer } from "./qualityLevel/qualitylevel.reducer";
import { withdrawRequestReducer } from "./withdraw/withdraw.reducer";
import { ActiveTaskReducer } from "./activeTask/activeTask.reducer";

export default combineReducers({
  dialogue: dialogueReducer,
  admin: adminReducer,
  dashboard: dashboardReducer,
  user: userReducer,
  coinPlan: coinPlanReducer,
  setting: settingReducer,
  agency: agencyReducer,
  host: hostReducer,
  fakeHost: fakeHostReducer,
  gift: giftReducer,
  category: giftCategoryReducer,
  flashCoin: flashCoinReducer,
  flashVip: flashVipReducer,
  feedBack: feedBackReducer,
  vipPlan: vipPlanReducer,
  commission: commissionReducer,
  settlement: settlementReducer,
  notificationDialog: notificationReducer,
  userReport: reportReducer,
  topic: topicReducer,
  banner: bannerReducer,
  fakeStory: fakeStoryReducer,
  vipLevel: levelReducer,
  income: IncomeReducer,
  reward: RewardReducer,
  dailyReward: DailyCheckInRewardReducer,
  qualityLevel: QualityReducer,
  activeTask : ActiveTaskReducer,
  withdrawRequest: withdrawRequestReducer
});
