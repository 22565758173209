import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import {
    getReward,
} from "../../store/reward/reward.action";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import EditInfo from "../../../assets/images/Edit.png";
import RewardLevelDialogue from "./RewardLevelDialogue";
const RewardLevel = (props) => {
    const { reward } = useSelector((state) => state.reward);
    const { dialogue, dialogueType, dialogueData } = useSelector(
        (state) => state.dialogue
    );
    const [startTime , setStartTime] = useState(null);
    const [endTime , setEndTime] = useState(null);
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [search, setSearch] = useState("ALL");
    const [data, setData] = useState([]);
    const [row,setRow] = useState("");

    

    useEffect(() => {
        dispatch(getReward());
    }, [dispatch, search]);

    useEffect(() => {
        setData(reward);
    }, [reward]);

    // Active Plan

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setPage(0);
    };

    const safeParseRow = (row) => {

        return {
            ...row,
            peakHours: row.peakHours
                ? {
                    ...row.peakHours,
                    startTime: row.peakHours.startTime
                        ? dayjs(row.peakHours.startTime).format("hh:mm A")
                        : null,
                    endTime: row.peakHours.endTime
                        ? dayjs(row.peakHours.endTime).format("hh:mm A")
                        : null,
                }
                : {
                    startTime: null,
                    endTime: null,
                },
        };
    };

    // Coin Plan Table
    const planTable = [
        {
            Header: "No",
            width: "20px",
            Cell: ({ index }) => <span>{index + 1}</span>,
        },

        {
            Header: "Level",
            body: "level",
            Cell: ({ row }) => <span>{row?.level ? row?.level : 0}</span>,
        },

        {
            Header: "Level Name",
            body: "level name",
            Cell: ({ row }) => <span>{row?.levelName}</span>,
        },

        {
            Header: "Weekly Income",
            body: "weekly income",
            Cell: ({ row }) => (
                <span>{row?.weeklyIncome ? row?.weeklyIncome : 0}</span>
            ),
        },

        {
            Header: "Total work time (Minute)",
            body: "total work time",

            Cell: ({ row }) => (
                <span>{row?.totalWorkTime ? row?.totalWorkTime : 0}</span>
            ),
        },

        {
            Header: "Work Time For PeakHours (Minute)",
            body: "work time for peakHours",
            Cell: ({ row }) => (
                <span>{row?.workTimeForPeakHours ? row?.workTimeForPeakHours : 0}</span>
            ),
        },

        {
            Header: "Reward",
            body: "reward",
            Cell: ({ row }) => <span>{row?.reward ? row?.reward : 0}</span>,
        },

        {
            Header: "Created Date",
            body: "createdAt",
            Cell: ({ row }) => (
                <span>{dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
            ),
        },

    
        {
            Header: "Edit",
            body: "edit",
            Cell: ({ row }) => {
                const handleEditClick = () => {
                    // Use optional chaining and nullish coalescing for safer access
                    const startTime = row?.peakHours?.startTime;
                    const endTime = row?.peakHours?.endTime;

                    // Use try-catch to handle potential parsing errors
                    let parsedStartTime = null;
                    let parsedEndTime = null;

                    try {
                        if (startTime) {
                            const dayjsStartTime = dayjs(startTime);
                            if (dayjsStartTime.isValid()) {
                                parsedStartTime = dayjsStartTime.format("hh:mm A");
                            }
                        }
                        if (endTime) {
                            const dayjsEndTime = dayjs(endTime);
                            if (dayjsEndTime.isValid()) {
                                parsedEndTime = dayjsEndTime.format("hh:mm A");
                            }
                        }
                    } catch (error) {
                        
                        console.error("Error parsing date:", error);
                        // Handle the error appropriately, e.g., display a message to the user.
                    }

                    // ... rest of your code ...
                    dispatch({
                        type: OPEN_DIALOGUE,
                        payload: {
                            data: {
                                ...row
                            },
                            type: "reward",
                        },
                    });
                };

                return (
                    <img
                        src={EditInfo}
                        height={30}
                        width={30}
                        alt="Edit"
                        onClick={handleEditClick}
                    />
                );
            },
        },


        // add more columns as needed
    ];

    const handleFilterData = (filteredData) => {
        if (typeof filteredData === "string") {
            setSearch(filteredData);
        } else {
            setData(filteredData);
        }
    };

    return (
        <>
            <div className="mainPlanTable">
                <div className="planTable">
                    <div className="planHeader primeHeader">
                        <div className="col">
                            <Title name={`Reward Level`} />
                        </div>
                        <div className="row g-0 mt-3">
                            <div className="col-12 boxBetween ">
                                <div className="col-md-6 col-sm-12 my-2">
                                    <Button
                                        newClass={`border text-white userBtn`}
                                        btnColor={`bg-primary`}
                                        btnName={`Add`}
                                        btnIcon={`fas fa-plus fa-md`}
                                        onClick={() => {
                                            dispatch({
                                                type: OPEN_DIALOGUE,
                                                payload: { type: "reward" },
                                            });
                                        }}
                                        style={{
                                            borderRadius: "5px",
                                            width: "120px",
                                        }}
                                    />

                                    {dialogue && dialogueType === "reward" && <RewardLevelDialogue />}
                                </div>
                                <div className="d-flex justify-content-end col-md-6 col-sm-12">
                                    <Searching
                                        type={"server"}
                                        data={reward}
                                        setData={setData}
                                        setSearchData={setSearch}
                                        onFilterData={handleFilterData}
                                        serverSearching={handleFilterData}
                                        button={true}
                                        column={planTable}
                                        placeholder={"Searching Coin plan..."}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="userMain">
                        <div className="tableMain mt-3">
                            <Table
                                type={"client"}
                                data={data}
                                mapData={planTable}
                                PerPage={rowsPerPage}
                                Page={page}
                            />
                        </div>
                        <Pagination
                            type={"client"}
                            component="div"
                            count={reward?.length}
                            serverPage={page}
                            onPageChange={handleChangePage}
                            serverPerPage={rowsPerPage}
                            totalData={reward?.length}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        {/* <Table data={data} columns={planTable} searching={true} /> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, {
    getReward,
})(RewardLevel);
