import * as ActionType from "./activeTask.type";


const initialState = {
    activeTask: [],
    total: null,
};

export const ActiveTaskReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.GET_ACTIVE_TASK:
            return {
                ...state,
                activeTask: action.payload,
            };
        case ActionType.CREATE_ACTIVE_TASK:
            let data = [...state.activeTask];
            data.unshift(action.payload);
            return {
                ...state,
                activeTask: data,
            };
        case ActionType.EDIT_ACTIVE_TASK:
            
            return {
                ...state,
                activeTask: state.activeTask.map((data) =>
                    data._id === action.payload.taskId ? action.payload.data : data
                ),
            };
        case ActionType.DELETE_ACTIVE_TASK:
            return {
                ...state,
                activeTask: state.activeTask.filter((activeTask) => activeTask._id !== action.payload),
            };
        default:
            return state;
    }
};
