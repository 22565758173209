import { useEffect, useState } from "react";
import Input from "../../extra/Input";
import Title from "../../extra/Title";
import SettingBox from "./SettingBox";
import Button from "../../extra/Button";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getSetting,
  updateSetting,
  isAppActiveSwitch,
  allSwitchType,
  isHostAutoCall,
} from "../../store/setting/setting.action";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from "dayjs";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';



const Setting = (props) => {
  const { setting } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  // box 1
  const [agoraKey, setAgoraKey] = useState("");
  const [agoraCertificate, setAgoraCertificate] = useState("");
  // box 2
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");
  const [privacyPolicyText, setPrivacyPolicyText] = useState("");
  const [appVersion, setAppVersion] = useState(0);
  const [termAndCondition, setTermAndCondition] = useState("");
  const [googleInAppKey, setGoogleInAppKey] = useState("");
  const [googleInAppEmail, setGoogleInAppEmail] = useState("");
  const [upiPayKey, setUpiPayKey] = useState("");
  const [isAppActive, setAppIsAppActive] = useState(false);
  const [isFakeCall, setIsFakeCall] = useState(false);
  const [googleLogin, setGoogleLogin] = useState(false);
  const [quickLogin, setQuickLogin] = useState(false);
  const [razorPaySwitch, setRazorPaySwitch] = useState(false);
  const [stripePay, setStripePay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);
  const [phonePe, setPhonePe] = useState(false);
  const [paytm, setPaytm] = useState(false);
  const [bhim, setBhim] = useState(false);
  const [googleInAppSwitch, setGoogleInAppSwitch] = useState(false);
  const [upiPaySwitch, setUpiPaySwitch] = useState(false);
  const [chargeForMatchMale, setChargeForMatchMale] = useState(0);
  const [durationOfFreeCall, setDurationOfFreeCall] = useState(0);
  const [minPrivateCallCharge, setMinPrivateCallCharge] = useState(0);
  const [chargeForMatchFemale, setChargeForMatchFemale] = useState(0);
  const [chargeForMessage, setChargeForMessage] = useState(0);
  const [loginBonus, setLoginBonus] = useState(0);
  const [maxLoginBonus, setMaxLoginBonus] = useState(0);
  const [coinPerDollar, setCoinPerDollar] = useState(0);
  const [fakeCallDuration, setFakeCallDuration] = useState(0);
  const [fakeCallCount, setFakeCallCount] = useState(0);
  const [giftTax, setGiftTax] = useState(0);
  const [callTax, setCallTax] = useState(0);
  const [hostPrivacyPolicyLink, setHostPrivacyPolicyLink] = useState("");
  const [zigoCertificate, setZigoCertificate] = useState("");
  const [zigoId, setZigoId] = useState("");
  const [referralBonus, setReferralBonus] = useState("");
  const [maxLiveViewHost, setMaxLiveViewHost] = useState("");
  const [minLiveViewHost, setMinLiveViewHost] = useState("");
  const [hostAutoCall, setHostAutoCall] = useState(false);
  // box-5
  const [razorPayPublishableKey, setRazorPayPublishableKey] = useState("");
  const [startTime, setStartTime] = useState(dayjs("", "hh:mm A"));
  const [endTime, setEndTime] = useState(dayjs("", "hh:mm A"));
  const [razorPaySecretKy, setRazorPaySecretKy] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [stripePublishableKey, setStripePublishableKey] = useState("");
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");

  console.log("openTime", openTime);
  console.log("closeTime", closeTime)

  const [error, setError] = useState({
    chargeForMatchMale: "",
    chargeForMatchFemale: "",
    chargeForMessage: "",
    loginBonus: "",
    maxLoginBonus: "",
    termAndCondition: "",
    coinPerDollar: "",
    minPrivateCallCharge: "",
    privacyPolicyLink: "",
    giftTax: "",
    durationOfFreeCall: "",
    callTax: "",
    zigoCertificate: "",
    zigoId: "",
    referralBonus: "",
    hostPrivacyPolicyLink: "",
    stripeSecretKey: "",
    stripePublishableKey: "",
    razorPaySecretKy: "",
    razorPayPublishableKey: "",
    maxLiveViewHost: "",
    minLiveViewHost: "",
    fakeCallDuration: "",
    fakeCallCount: "",
    upiPayKey: "",
    googleInAppKey: "",
    googleInAppEmail: "",
    appVersion: "",
    hostAutoCall: ""
  });

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  useEffect(() => {
    // box 1
    setAgoraKey(setting?.agoraKey);
    setAgoraCertificate(setting?.agoraCertificate);
    // box 2
    setPrivacyPolicyLink(setting?.privacyPolicyLink);
    setPrivacyPolicyText(setting?.privacyPolicyText);
    setAppVersion(setting?.appVersion);
    setTermAndCondition(setting?.termAndCondition);
    setAppIsAppActive(setting?.isAppActive);
    setIsFakeCall(setting?.isFakeCall);
    setGoogleLogin(setting?.googleLogin);
    setQuickLogin(setting?.quickLogin);
    setStripePay(setting?.stripeSwitch);
    setBhim(setting?.bhim);
    setGooglePay(setting?.googlePay);
    setPhonePe(setting?.phonePe);
    setPaytm(setting?.paytm);
    setRazorPaySwitch(setting?.razorpaySwitch);
    setUpiPaySwitch(setting?.upiPaySwitch);
    setGoogleInAppSwitch(setting?.googleInAppSwitch);
    // box 3
    setChargeForMatchMale(setting?.chargeForMatchMale);
    setDurationOfFreeCall(setting?.durationOfFreeCall);
    setMinPrivateCallCharge(setting?.minPrivateCallCharge);
    setChargeForMatchFemale(setting?.chargeForMatchFemale);
    setChargeForMessage(setting?.chargeForMessage);
    setLoginBonus(setting?.loginBonus);
    setMaxLoginBonus(setting?.maxLoginBonus);
    setCoinPerDollar(setting?.coinPerDollar);
    setGiftTax(setting?.giftTax);
    setCallTax(setting?.callTax);
    setZigoCertificate(setting?.zigoCertificate);
    setZigoId(setting.zigoId);
    setReferralBonus(setting.referralBonus);
    setHostAutoCall(setting.hostCallAuto);
    setStartTime(dayjs(setting?.peakHourPeriod?.startPeriod, "hh:mm A"));
    setEndTime(dayjs(setting?.peakHourPeriod?.endPeriod, "hh:mm A"));
    // box -4
    setRazorPayPublishableKey(setting?.razorPayPublishableKey);
    setRazorPaySecretKy(setting?.razorPaySecretKy);
    setStripePublishableKey(setting?.stripePublishableKey);
    setStripeSecretKey(setting?.stripeSecretKey);
    setUpiPayKey(setting?.upiPayKey);
    setGoogleInAppKey(setting?.googleInAppKey);
    setGoogleInAppEmail(setting?.googleInAppEmail);
    googleInAppEmail;

    // box-5

    setHostPrivacyPolicyLink(setting.hostPrivacyPolicyLink);

    // box-6
    setMaxLiveViewHost(setting.maxLiveViewHost);
    setMinLiveViewHost(setting.minLiveViewHost);
    // box-6
    setFakeCallDuration(setting?.fakeCallDuration);
    setFakeCallCount(setting?.fakeCallCount);
  }, [setting]);

  const handleEndTimeChange = (value) => {

    if (value && value.isValid()) { // Check if the value is valid
      const formattedTime = value.format("hh:mm A"); // Format the time as required
      setCloseTime(formattedTime)
      setEndTime(value); // Update the state with the full dayjs object
      setError({ ...error, endTime: "" }); // Clear any existing errors
    } else {
      setError({ ...error, endTime: "Invalid End Time" });
    }
  };

  const handleStartTimeChange = (value) => {
    if (value && value.isValid()) { // Check if the value is valid
      const formattedTime = value.format("hh:mm A"); // Format the time as required
      setOpenTime(formattedTime)
      setStartTime(value); // Update the state with the full dayjs object
      setError({ ...error, startTime: "" }); // Clear any existing errors
    } else {
      setError({ ...error, startTime: "Invalid Start Time" });
    }
  };

  const handleSubmit = () => {
    let settingData = {
      agoraKey,
      stripeSecretKey,
      stripePublishableKey,
      razorPaySecretKy,
      razorPayPublishableKey,
      quickLogin,
      googleLogin,
      razorPaySwitch,
      stripePay,
      bhim,
      googlePay,
      phonePe,
      paytm,
      upiPaySwitch,
      googleInAppSwitch,
      zigoCertificate,
      zigoId,
      agoraCertificate,
      privacyPolicyLink,
      privacyPolicyText,
      appVersion,
      termAndCondition,
      isAppActive,
      isFakeCall,
      fakeCallDuration,
      fakeCallCount,
      chargeForMatchMale,
      chargeForMatchFemale,
      chargeForMessage,
      loginBonus,
      maxLoginBonus,
      coinPerDollar,
      giftTax,
      callTax,
      minPrivateCallCharge,
      durationOfFreeCall,
      referralBonus,
      hostPrivacyPolicyLink,
      minLiveViewHost,
      maxLiveViewHost,
      googleInAppKey,
      googleInAppEmail,
      upiPayKey,
      startPeriod: openTime,
      endPeriod: closeTime
    };

    if (
      // chargeForMatchMale === "" ||
      !zigoCertificate ||
      !zigoId ||
      // termAndCondition === "" ||
      // privacyPolicyLink === "" ||
      // !googleInAppKey ||
      // !googleInAppEmail ||
      // !upiPayKey ||
      // chargeForMatchFemale === "" ||
      // chargeForMessage === "" ||
      coinPerDollar === "" ||
      // loginBonus === "" ||
      // maxLoginBonus === "" ||
      !hostPrivacyPolicyLink ||
      minPrivateCallCharge === "" ||
      // durationOfFreeCall === "" ||
      // appVersion === "" ||
      callTax === "" ||
      giftTax === "" ||
      // referralBonus === "" ||
      // !stripeSecretKey ||
      // !stripePublishableKey ||
      // !razorPaySecretKy ||
      // !razorPayPublishableKey ||
      // minLiveViewHost === "" ||
      // maxLiveViewHost === "" ||
      // chargeForMatchMale < 0 ||
      // chargeForMatchFemale < 0 ||
      // chargeForMessage < 0 ||
      coinPerDollar < 0 ||
      // loginBonus < 0 ||
      // maxLoginBonus < 0 ||
      // appVersion < 0 ||
      minPrivateCallCharge < 0 ||
      // durationOfFreeCall < 0 ||
      callTax < 0 ||
      giftTax < 0
      // referralBonus < 0 ||
      // minLiveViewHost < 0 ||
      // maxLiveViewHost < 0 ||
      // fakeCallDuration === "" ||
      // fakeCallDuration < 0 ||
      // fakeCallCount === "" ||
      // fakeCallCount < 0
    ) {
      let error = {};
      if (coinPerDollar < 0) error.coinPerDollar = "Invalid Details";
      if (coinPerDollar === "") error.coinPerDollar = "Required";
      if (!hostPrivacyPolicyLink) error.hostPrivacyPolicyLink = "Required";
      if (minPrivateCallCharge < 0)
        error.minPrivateCallCharge = "Invalid Details";
      if (minPrivateCallCharge === "") error.minPrivateCallCharge = "Required";
      if (callTax < 0) error.callTax = "Invalid Details";
      if (callTax === "") error.callTax = "Required";
      if (giftTax < 0) error.giftTax = "Invalid Details";
      if (giftTax === "") error.giftTax = "Required";
      if (!zigoId) error.zigoId = "Required";
      if (!zigoCertificate) error.zigoCertificate = "Required";
      // if (durationOfFreeCall < 0) error.durationOfFreeCall = "Invalid Details";
      // if (durationOfFreeCall === "") error.durationOfFreeCall = "Required";
      // if (chargeForMatchFemale < 0)
      //   error.chargeForMatchFemale = "Invalid Details";
      // if (chargeForMatchFemale === "") error.chargeForMatchFemale = "Required";
      // if (chargeForMessage < 0) error.chargeForMessage = "Invalid Details";
      // if (chargeForMessage === "") error.chargeForMessage = "Required";
      // if (loginBonus < 0) error.loginBonus = "Invalid Details";
      // if (loginBonus === "") error.loginBonus = "Required";
      // if (maxLoginBonus < 0) error.maxLoginBonus = "Invalid Details";
      // if (maxLoginBonus === "") error.maxLoginBonus = "Required";
      // if (referralBonus < 0) error.referralBonus = "Invalid Details";
      // if (referralBonus === "") error.referralBonus = "Required";
      // if (minLiveViewHost < 0) error.minLiveViewHost = "Invalid Details";
      // if (minLiveViewHost === "") error.minLiveViewHost = "Required";
      // if (maxLiveViewHost < 0) error.maxLiveViewHost = "Invalid Details";
      // if (maxLiveViewHost === "") error.maxLiveViewHost = "Required";
      // if (chargeForMatchMale < 0) error.chargeForMatchMale = "Invalid Details";
      // if (chargeForMatchMale == "") error.chargeForMatchMale = "Required";
      // if (fakeCallDuration < 0) error.fakeCallDuration = "Invalid Details";
      // if (fakeCallDuration === "") error.fakeCallDuration = "Required";
      // if (fakeCallCount < 0) error.fakeCallCount = "Invalid Details";
      // if (fakeCallCount === "") error.fakeCallCount = "Required";
      // if (appVersion < 0) error.appVersion = "Invalid Details";
      // if (appVersion === "") error.appVersion = "Required";
      // if (!googleInAppKey) error.googleInAppKey = "Required";
      // if (!googleInAppEmail) error.googleInAppEmail = "Required";
      // if (!upiPayKey) error.upiPayKey = "Required";
      // if (!stripeSecretKey) error.stripeSecretKey = "Required";
      // if (!stripePublishableKey) error.stripePublishableKey = "Required";
      // if (!razorPaySecretKy) error.razorPaySecretKy = "Required";
      // if (!razorPayPublishableKey) error.razorPayPublishableKey = "Required";
      // if (termAndCondition === "") error.termAndCondition = "Required";
      // if (privacyPolicyLink === "") error.privacyPolicyLink = "Required";

      return setError({ ...error });
    } else {
      props.updateSetting(settingData, setting._id);
    }
  };
  const handleClickAllSwitch = (type) => {
    dispatch(isHostAutoCall(setting?._id, type));
  };

  return (
    <>
      <div className="mainSettingBar">
        <div className="settingBar ">
          <div className="aaaa">
            <div className="settingHeader primeHeader">
              <div className="row">
                <Title name={`Setting`} />
              </div>
            </div>
          </div>
          <div className="settingMain appBox">
            <div className="row">
              <div className="col-xl-6 col-12 mt-3">
                <SettingBox
                  title={`Zigo Setting`}
                // toggleSwitch={{
                //   switchName: "Is App Active",
                //   switchValue: isAppActive,
                //   handleClick: () => {
                //     handleClick();
                //   },
                // }}
                >
                  {/* <Input
                    type={`text`}
                    label={`Privacy Policy Link`}
                    value={privacyPolicyLink}
                    newClass={`col-lg-6 col-sm-12`}
                    errorMessage={error.privacyPolicyLink}
                    placeholder={`Enter You Privacy Policy Link....`}
                    onChange={(e) => {
                      setPrivacyPolicyLink(e.target.value);
                    }}
                  /> */}
                  {/* <Input
                  type={`text`}
                  label={`Privacy Policy Text`}
                  value={privacyPolicyText}
                  newClass={`col-lg-12 col-sm-12`}
                  placeholder={`Enter You Privacy Policy Text....`}
                  onChange={(e) => {
                    setPrivacyPolicyText(e.target.value);
                  }}
                /> */}
                  {/* <Input
                    type={`text`}
                    label={`Term And Condition Link`}
                    value={termAndCondition}
                    newClass={`col-md-6 col-12`}
                    errorMessage={error.termAndCondition}
                    placeholder={`Enter You Term And Condition Link....`}
                    onChange={(e) => {
                      setTermAndCondition(e.target.value);
                    }}
                  /> */}

                  <Input
                    type={`text`}
                    label={`Certificate`}
                    value={zigoCertificate}
                    newClass={`col-lg-12 col-sm-12`}
                    placeholder={`Zigo Certificate....`}
                    errorMessage={error.zigoCertificate}
                    onChange={(e) => {
                      setZigoCertificate(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          zigoCertificate: "required",
                        });
                      } else {
                        return setError({
                          ...error,
                          zigoCertificate: "",
                        });
                      }
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Zigo Id`}
                    value={zigoId}
                    newClass={`col-lg-12 col-sm-12`}
                    placeholder={`Enter You Zigo Id....`}
                    errorMessage={error.zigoId}
                    onChange={(e) => {
                      setZigoId(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          zigoId: "required",
                        });
                      } else {
                        return setError({
                          ...error,
                          zigoId: "",
                        });
                      }
                    }}
                  />
                  {/* <Input
                    type={`number`}
                    label={`App Version`}
                    value={appVersion}
                    newClass={`col-lg-12 col-sm-12`}
                    // placeholder={`Enter You Zigo Id....`}
                    errorMessage={error.appVersion}
                    onChange={(e) => {
                      setAppVersion(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          appVersion: "required",
                        });
                      } else {
                        return setError({
                          ...error,
                          appVersion: "",
                        });
                      }
                    }}
                  /> */}
                </SettingBox>
                {/* <div className="mt-3">
                  <SettingBox
                    title={`Fake Call Setting`}
                    toggleSwitch={{
                      switchName: "Is Fake Call",
                      switchValue: isFakeCall,
                      handleClick: () => {
                        handleClickAllSwitch("fakeCall");
                      },
                    }}
                    className={`text-white`}
                  >
                    <Input
                      type={`number`}
                      label={`Call Duration`}
                      value={fakeCallDuration}
                      disabled={isFakeCall === false ? true : false}
                      errorMessage={error.fakeCallDuration}
                      newClass={`col-lg-6 col-sm-12 mt-1`}
                      style={{ marginTop: "4px" }}
                      placeholder={``}
                      onChange={(e) => {
                        setFakeCallDuration(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            fakeCallDuration: "required",
                          });
                        } else
                          return setError({
                            ...error,
                            fakeCallDuration: "",
                          });
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`Call Count`}
                      value={fakeCallCount}
                      disabled={isFakeCall === false ? true : false}
                      errorMessage={error.fakeCallCount}
                      newClass={`col-lg-6 col-sm-12 mt-1`}
                      style={{ marginTop: "4px" }}
                      placeholder={``}
                      onChange={(e) => {
                        setFakeCallCount(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            fakeCallCount: "required",
                          });
                        } else
                          return setError({
                            ...error,
                            fakeCallCount: "",
                          });
                      }}
                    />
                  </SettingBox>
                </div> */}

                {/* ============= Box 5 ============= */}

                <div className="mt-3"></div>
              </div>
              <div className="col-md-6 col-12 mt-3">
                <SettingBox title={`Call Setting`}>
                  <Input
                    type={`number`}
                    label={`Gift Tax (%)`}
                    value={giftTax}
                    errorMessage={error.giftTax}
                    newClass={`col-lg-6 col-md-6 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setGiftTax(inputValue);

                      if (!inputValue) {
                        setError({
                          ...error,
                          giftTax: "required",
                        });
                      } else {
                        setError({
                          ...error,
                          giftTax: "",
                        });
                      }
                    }}
                  />

                  <Input
                    type={`number`}
                    label={`Call Tax (%)`}
                    value={callTax}
                    errorMessage={error.callTax}
                    newClass={`col-lg-6 col-md-6 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setCallTax(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          callTax: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          callTax: "",
                        });
                    }}
                  />

                  <Input
                    type={`number`}
                    label={`Coin Per Dollar`}
                    value={coinPerDollar}
                    errorMessage={error.coinPerDollar}
                    newClass={`col-lg-6 col-md-6 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setCoinPerDollar(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          coinPerDollar: "required",
                        });
                      } else {
                        return setError({
                          ...error,
                          coinPerDollar: "",
                        });
                      }
                    }}
                  />
                  {/* <Input
                    type={`number`}
                    label={`Private Call Charge`}
                    value={minPrivateCallCharge}
                    errorMessage={error.minPrivateCallCharge}
                    newClass={`col-lg-6 col-md-6 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setMinPrivateCallCharge(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          minPrivateCallCharge: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          minPrivateCallCharge: "",
                        });
                    }}
                  /> */}


                
                </SettingBox>
              </div>
              <div className="col-xl-6 col-12 mt-3">
                <SettingBox title={`Host Setting`}>
                  <Input
                    type={`text`}
                    label={`Privacy Policy Link`}
                    value={hostPrivacyPolicyLink}
                    errorMessage={error.hostPrivacyPolicyLink}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setHostPrivacyPolicyLink(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          hostPrivacyPolicyLink: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          hostPrivacyPolicyLink: "",
                        });
                    }}
                  />

                  <div className="switchTitleColor">
                    <span className=" fs-6 col-2 prime-time">Is Host AutoCall </span>
                    <ToggleSwitch
                      onClick={() => {
                        handleClickAllSwitch("hostCallAuto");
                      }}
                      value={hostAutoCall}
                    />
                  </div>
                </SettingBox>


              </div>
              {/* ============= Box 6 ============= */}

              <div className="col-xl-6 col-12 mt-3">
                <SettingBox title={`Live View Setting`}>
                  <Input
                    type={`number`}
                    label={`Min Live View (Host)`}
                    value={minLiveViewHost}
                    errorMessage={error.minLiveViewHost}
                    newClass={`col-lg-6 col-md-6 col-sm-12`}
                    placeholder={``}
                    onFocus={(e) => {
                      e.target.value == 0 ? setminLiveViewHost("") : "";
                    }}
                    onChange={(e) => {
                      setMinLiveViewHost(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          minLiveViewHost: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          minLiveViewHost: "",
                        });
                    }}
                  />

                  <Input
                    type={`number`}
                    label={`Max Live View (Host)`}
                    value={maxLiveViewHost}
                    errorMessage={error.maxLiveViewHost}
                    newClass={`col-lg-6 col-md-6 col-sm-12`}
                    placeholder={``}
                    onFocus={(e) => {
                      e.target.value == 0 ? setMaxLiveViewHost("") : "";
                    }}
                    onChange={(e) => {
                      setMaxLiveViewHost(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          maxLiveViewHost: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          maxLiveViewHost: "",
                        });
                    }}
                  />
                </SettingBox>
              </div>


              <div className="col-xl-6 col-6 mt-3">
              <SettingBox title={`Determining Peak Call Hours for Analytics`}>

                <div className="col-6">
                  <label className="prime-time">Start Time (India TimeZone)</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker
                        value={startTime}
                        onChange={handleStartTimeChange}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        sx={{
                          '.MuiOutlinedInput-root': {
                            '& input': {
                              overflow: 'auto', // Prevents scroll
                            },
                            '& fieldset': {
                              borderColor: '#cbd5e1', // Default border color
                            },
                            '&:hover fieldset': {
                              borderColor: '#cbd5e1', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#4caf50', // Green border color when focused
                            },
                            '&.Mui-error fieldset': {
                              borderColor: error.startTime ? '#cbd5e1' : '#f44336', // Remove red border if no error
                            },
                          },
                          '.MuiClock-root': {
                            position: 'relative', // Adjust position
                            top: '500px', // Move clock 50px down
                            left: '20px', // Move clock 20px to the right
                            transform: 'translate(-50%, -50%)', // Optional: For centering
                          },
                        }}
                      />

                    </DemoContainer>
                  </LocalizationProvider>
                  {error.startTime && <small style={{ color: "red" }}>{error.startTime}</small>}
                </div>
                
                <div className="col-6">
                    <label className="prime-time">End Time (India TimeZone)</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['TimePicker']}>
                        <TimePicker
                          value={endTime}
                          onChange={handleEndTimeChange}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{
                            '.MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#cbd5e1', // Default border color
                              },
                              '&:hover fieldset': {
                                borderColor: '#cbd5e1', // Border color on hover
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#cbd5e1', // Border color when focused
                              },
                            },
                            '.MuiClock-root': {
                              position: 'relative', // Adjust position
                              top: '500px', // Move clock 50px down
                              left: '20px', // Move clock 20px to the right
                              transform: 'translate(-50%, -50%)', // Optional: For centering,
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {error.endTime && <small style={{ color: "red" }}>{error.endTime}</small>}
                  </div>

                  </SettingBox>
              </div>



              {/* ============= Box 2 ============= */}

              {/* <div className="col-xl-6 col-12 mt-3">
                <SettingBox title={`Payment Setting`}>
                  <div
                    className="d-flex justify-content-between mb-4 mt-1 switch-one "
                    style={{ padding: "13px" }}
                  >
                    <div className="switchTitleColor text-center ">
                      <span className="fw-bold fs-6 col-2">Razor Pay</span>
                      <ToggleSwitch
                        onClick={() => {
                          handleClickAllSwitch("razorpay");
                        }}
                        value={razorPaySwitch}
                      />
                    </div>
                    <div className="switchTitleColor ms-5 text-center">
                      <span className="fw-bold fs-6 col-2">Stripe Pay</span>
                      <ToggleSwitch
                        onClick={() => {
                          handleClickAllSwitch("stripe");
                        }}
                        value={stripePay}
                      />
                    </div>
                    <div className="switchTitleColor  text-center">
                      <span className="fw-bold fs-6 col-2">UPI Payment</span>
                      <ToggleSwitch
                        onClick={() => {
                          handleClickAllSwitch("upiPaySwitch");
                        }}
                        value={upiPaySwitch}
                      />
                    </div>
                    <div className="switchTitleColor  text-center">
                      <span className="fw-bold fs-6 col-2">Google In App</span>
                      <ToggleSwitch
                        onClick={() => {
                          handleClickAllSwitch("googleInAppSwitch");
                        }}
                        value={googleInAppSwitch}
                      />
                    </div>
                  </div>
                  <h5> UPI Pay Enable/Disable Setting </h5>
                  <div
                    className=" mt-4 mb-xl-4 mb-md-2 switch px-2 d-flex justify-content-between"
                    style={{ opacity: upiPaySwitch === false ? "0.4" : "1" }}
                  >
                    <div className="switchTitleColor text-center">
                      <span className="fw-bold fs-6 col-2">GooglePay </span>
                      <ToggleSwitch
                        onClick={() => {
                          handleClickAllSwitch("googlePay");
                        }}
                        value={googlePay}
                      />
                    </div>
                    <div className="switchTitleColor text-center">
                      <span className="fw-bold fs-6 col-2">PhonePe </span>
                      <ToggleSwitch
                        onClick={() => {
                          handleClickAllSwitch("phonePe");
                        }}
                        value={phonePe}
                      />
                    </div>
                    <div className="switchTitleColor text-center">
                      <span className="fw-bold fs-6 col-2">Paytm </span>
                      <ToggleSwitch
                        onClick={() => {
                          handleClickAllSwitch("paytm");
                        }}
                        value={paytm}
                      />
                    </div>
                    <div className="switchTitleColor text-center ">
                      <span className="fw-bold fs-6 col-2">BhimPay</span>
                      <ToggleSwitch
                        onClick={() => {
                          handleClickAllSwitch("bhim");
                        }}
                        value={bhim}
                      />
                    </div>
                  </div>
                  <Input
                    type={`text`}
                    label={`Razor SecretKey `}
                    value={razorPaySecretKy}
                    errorMessage={error.razorPaySecretKy}
                    newClass={`col-lg-6 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setRazorPaySecretKy(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          razorPaySecretKy: "required",
                        });
                      } else {
                        return setError({
                          ...error,
                          razorPaySecretKy: "",
                        });
                      }
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Razor PublishableKey`}
                    value={razorPayPublishableKey}
                    errorMessage={error.razorPayPublishableKey}
                    newClass={`col-lg-6 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setRazorPayPublishableKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          razorPayPublishableKey: "required",
                        });
                      } else {
                        return setError({
                          ...error,
                          razorPayPublishableKey: "",
                        });
                      }
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Stripe SecretKey `}
                    value={stripeSecretKey}
                    errorMessage={error.stripeSecretKey}
                    newClass={`col-lg-6 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setStripeSecretKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          stripeSecretKey: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          stripeSecretKey: "",
                        });
                    }}
                  />

                  <Input
                    type={`text`}
                    label={`Stripe PublishableKey `}
                    value={stripePublishableKey}
                    errorMessage={error.stripePublishableKey}
                    newClass={`col-lg-6 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setStripePublishableKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          stripePublishableKey: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          stripePublishableKey: "",
                        });
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`UPI Key`}
                    value={upiPayKey}
                    errorMessage={error.upiPayKey}
                    newClass={`col-lg-12 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setUpiPayKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          upiPayKey: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          upiPayKey: "",
                        });
                    }}
                  />
                </SettingBox>
              </div> */}

              {/* ============= Box 3 ============= */}

              <div className="col-xl-6 col-12 mt-3">
                {/* <div className="col-xl-12 col-12 mt-3">
                <SettingBox title={`Coin Setting `}>
                  {/* <Input
                    type={`number`}
                    label={`Charge for Male`}
                    value={chargeForMatchMale}
                    errorMessage={error.chargeForMatchMale}
                    newClass={`col-lg-6 col-md-6 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setChargeForMatchMale(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          chargeForMatchMale: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          chargeForMatchMale: "",
                        });
                    }}
                  />

                  <Input
                    type={`number`}
                    label={`Charge for Female`}
                    value={chargeForMatchFemale}
                    errorMessage={error.chargeForMatchFemale}
                    newClass={`col-lg-6 col-md-6 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setChargeForMatchFemale(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          chargeForMatchFemale: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          chargeForMatchFemale: "",
                        });
                    }}
                  />
                  <Input
                    type={`number`}
                    label={`Duration Of FreeCall`}
                    value={durationOfFreeCall}
                    errorMessage={error.durationOfFreeCall}
                    newClass={`col-lg-12 col-md-12 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setDurationOfFreeCall(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          durationOfFreeCall: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          durationOfFreeCall: "",
                        });
                    }}
                  /> *
                  <Input
                    type={`number`}
                    label={`Private Call Charge`}
                    value={minPrivateCallCharge}
                    errorMessage={error.minPrivateCallCharge}
                    newClass={`col-lg-12 col-md-12 col-sm-12`}
                    placeholder={``}
                    onChange={(e) => {
                      setMinPrivateCallCharge(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          minPrivateCallCharge: "required",
                        });
                      } else
                        return setError({
                          ...error,
                          minPrivateCallCharge: "",
                        });
                    }}
                  />
                  {/* <Input
                  type={`number`}
                  label={`Charge for Message`}
                  value={chargeForMessage}
                  errorMessage={error.chargeForMessage}
                  newClass={`col-lg-12   col-sm-12`}
                  placeholder={``}
                  onChange={(e) => {
                    setChargeForMessage(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,

                        chargeForMessage: "required",
                      });
                    } else
                      return setError({
                        ...error,
                        chargeForMessage: "",
                      });
                  }}
                /> *
                </SettingBox>
              </div> */}
              </div>

              {/* ============= Box 4 ============= */}
            </div>
          </div>
          <div className="settingMain pb-sm-0" style={{ width: "100%" }}>
            <div className="row d-flex align-items-start">
              {/* <div className="col-xl-6 col-md-9 col-sm-8 m-auto  order-sm-1 order-md-0  mb-3">
                  <div className="loginType">
                    <table className="w-100">
                      <tbody>
                        <tr>
                          <td
                            className="fw-bolder text-dark bgSetting"
                            style={{
                              borderRight: "1px solid",
                              fontSize: "17px",
                            }}
                          >
                            Login Types
                          </td>
                          <td className="bgSetting">
                            <span className="fw-bold">Google Active</span>
                            <ToggleSwitch
                              onClick={() => {
                                handleClickAllSwitch("google");
                              }}
                              value={googleLogin}
                            />
                          </td>
                          <td className="bgSetting text-end">
                            <span className="fw-bold">Quick Active</span>
                            <ToggleSwitch
                              onClick={() => {
                                handleClickAllSwitch("quick");
                              }}
                              value={quickLogin}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> */}
              <div className="col-xl-12 col-md-3 col-sm-4 d-flex justify-content-end align-items-center order-sm-0 order-md-1 ">
                <div className="d-flex align-items-center">
                  <Button
                    btnName={`Submit`}
                    btnIcon={`fa-solid fa-circle-arrow-right ms-2 `}
                    btnColor={`btnBlackPrime`}
                    style={{
                      width: "140px",
                      borderRadius: "6px",
                      display: "flex",

                      borderRadius: "6px",
                      flexDirection: "row-reverse",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getSetting,
  updateSetting,
  isAppActiveSwitch,
  allSwitchType,
})(Setting);

{
  /* ============= Box 7 ============= */
}
{
  /* <div
                style={{
                  opacity: isFakeCall === false ? "0.4" : "1",
                  borderRadius: "10px",
                }}
              >
              
            </div> */
}
