import React, { useEffect, useState } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { permissionError } from "../../util/Alert";
import Button from "../../extra/Button";
import { createNewGift, editGift } from "../../store/Gift/gift.action";
import { getCategory } from "../../store/giftCategory/action";
import ReactDropzone from "react-dropzone";
import { baseURL } from "../../util/config";
import CancelIcon from "@mui/icons-material/Cancel"

const GiftDialog = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { category } = useSelector((state) => state.category);

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  console.log("dialogueData" , dialogueData)

  const [mongoId, setMongoId] = useState(0);
  const [coin, setCoin] = useState("");
  const [name , setName] = useState("");
  const [categories, setCategories] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState({
    coin: "",
    name : "",
    image: "",
    categories: "",
  });
  const dispatch = useDispatch();

  console.log("image" , image)

  useEffect(() => {
    setError({
      image: "",
      coin: "",
      name : "",
      categories: "",
    });
    setCategories("");
    setCoin("");
    setName("");
    setImage([]);
  }, []);

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  useEffect(() => {
    setMongoId(dialogueData?._id);
    setCoin(dialogueData?.coin);
    setName(dialogueData?.name)
    setCategories(dialogueData?.categoryId);
    setImagePath(dialogueData?.image ? baseURL + dialogueData?.image : "");
  
  }, [dialogueData]);

  
  const onPreviewDrop = (files) => {
    setError({ ...error, image: "" });
    files.forEach((file, index) => {
      
      Object.assign(file, { preview: URL.createObjectURL(file) });
      setImage((prevImages) => prevImages.concat(file));
    });
  };

  
  const handleImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }


    setError((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const handleDeleteImg = (file123, index) => {
    const newFiles = [...image];
    image.splice(newFiles.indexOf(file123), 1);
    if (file123.preview) {
      const images = image.filter((ele) => {
        return ele.preview !== file123.preview;
      });
      setImagePath(images);
      setImage(images);
    } else {
      const newFiles = [...image];
      image.splice(newFiles.indexOf(file123), 1);
      setImage(newFiles);
    }
  };

  const handleSubmit = (e) => {
    if (
      !coin ||
      !name ||
      coin < 0 ||
      !categories
      // (GiftClick !== null && (categories === "Select Category" || !categories))
    ) {
      let error = {};
      if (!coin) error.coin = "Coin is Required!";
      if (!name) error.name = "Name is Required!";
      if (coin < 0) error.coin = "Invalid coin!";
      if (!image.length === 0 || !imagePath) error.image = "Image is Required!";
      if (!categories || categories === "Select categories name")
        error.categories = "Categories name is Required!";

      return setError({ ...error });
    } else {
      //   if (!hasPermission) return permissionError();

      const formData = new FormData();
      formData.append("coin", coin);
      formData.append("image", image);
      formData.append("categoryId", categories);
      formData.append("name" , name)

      if (mongoId) {
        props.editGift(formData, mongoId);
      } else {
        props.createNewGift(formData);
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };
  return (
    <div className="mainDialogue fade-in">
      <div
        className="Dialogue"
        style={{ width: "500px", overflow: "auto", maxHeight: "100vh" }}
      >
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Gift Dialog</div>
          <div
            className="closeDialogue text-center"
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <CancelIcon sx={{ fontSize: 35, color: "black", cursor: "pointer" }} />
            {/* <img src={cancel} height={30} width={30} alt="Edit" /> */}

          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">

          <div className="col-12">
              <Input
                label={`Name`}
                id={`name`}
                type={`text`}
                value={name}
                errorMessage={error.name && error.name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      name: `Name is required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      name: "",
                    });
                  }
                }}
              />
            </div>
            <div className="col-12">
              <Input
                label={`Coin`}
                id={`coin`}
                type={`number`}
                value={coin}
                errorMessage={error.coin && error.coin}
                onChange={(e) => {
                  setCoin(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      coin: `Coin is required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      coin: "",
                    });
                  }
                }}
              />
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label class="prime-time">
                  Category
                </label>
                {/* {GiftClick === null ? (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Category Name"
                    // value={categoryDetail?.name}
                  />
                ) : ( */}
                <>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={categories}
                    onChange={(e) => {
                      setCategories(e.target.value);
                      if (e.target.value === "Select Category") {
                        return setError({
                          ...error,
                          categories: "Please select a Category!",
                        });
                      } else {
                        return setError({
                          ...error,
                          categories: "",
                        });
                      }
                    }}
                  >
                    <option value="Select Category" selected>
                      Select Category
                    </option>
                    {category?.map((category12) => {
                      return (
                        <option value={category12._id}>
                          {category12.name}
                        </option>
                      );
                    })}
                  </select>
                  {error.categories && (
                    <div className="ml-2 mt-1">
                      {error.categories && (
                        <div className="pl-1 text__left">
                          <span className="text-error">{error.categories}</span>
                        </div>
                      )}
                    </div>
                  )}
                </>
                {/* )} */}
              </div>
            </div>

            <div className="col-12 mt-3">
              <Input
                label={`Image`}
                id={`image`}
                type={`file`}
                onChange={(e) => handleImage(e)}
                errorMessage={error.image && error.image}
              />
              {imagePath && (
                <div className="image-start">
                  <img
                    src={imagePath || Male}
                    alt="banner"
                    draggable="false"
                    width={100}
                    onError={(e) => e.target.src = Male}
                    className="m-0"
                  />
                </div>
              )}
            </div>

          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { createNewGift, editGift, getCategory })(
  GiftDialog
);
