import { connect, useDispatch, useSelector } from "react-redux";
import Title from "../extra/Title";
import Admin from "../../assets/images/admin.png";
import Addimg from "../../assets/images/addImg.jpg";
import Button from "../extra/Button";
import Input from "../extra/Input";
import { useEffect, useRef, useState } from "react";
import { setToast } from "../util/toast";
import {
  getProfile,
  changePassword,
  profileUpdate,
} from "../store/admin/admin.action";
import { permissionError } from "../util/Alert";
import { baseURL } from "../util/config";
const AdminProfile = (props) => {
  const admin = useSelector((state) => state.admin.admin);
  const hasPermission = useSelector((state) => state.admin.admin.flag);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [error, setError] = useState("");
  const inputFileRef = useRef(null);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [boxType, setBoxType] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  useEffect(() => {
    setName(admin?.name);
    setEmail(admin?.email);
    setImagePath(admin?.image);
    setError({ name: "", email: "" });
  }, [admin]);

  
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);


  const handleEditName = () => {
    // if (!hasPermission) return permissionError();
    if (!name || !email) {
      let error = {};
      if (!name) error.name = "Name is Required !";
      if (!email) error.email = "Email is Required !";
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);

      props.profileUpdate(formData);
    }
  };
  // const handleUploadImage = (e) => {
  //   setImage(e.target.files[0]);
  //   setImagePath(URL.createObjectURL(e.target.files[0]));
  // };

  // const handleEditImage = () => {
  //   const fileInput = document.getElementById("profileImage");
  //   fileInput.click();
  // };


  const handleUploadImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleUpdateImage = () => {
    // if (!hasPermission) return permissionError();
    if (!image || image.length === 0) {
      setToast("info", "Please select image");
    } else {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("name", name);
      formData.append("email", email);
      props.profileUpdate(formData);
      setImage(null);
    }
  };

  const handleChangePassword = () => {
    // if (!hasPermission) return permissionError();
    if (
      !newPassword ||
      !confirmPassword ||
      newPassword !== confirmPassword ||
      !oldPassword
    ) {
      let error = {};

      if (!newPassword) error.newPassword = "New password is required !";
      if (!confirmPassword)
        error.confirmPassword = "Confirm password Is required !";
      if (newPassword !== confirmPassword)
        error.confirmPassword =
          "New password and confirm password doesn't match";
      if (!oldPassword) error.oldPassword = "Old password is required !";
      return setError({ ...error });
    } else {
      let data = {
        oldPass: oldPassword,
        confirmPass: confirmPassword,
        newPass: newPassword,
      };
      props.changePassword(data);
    }
  };

  const handleEditImage = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click(); // Trigger the file input dialog
    }
  };

  const handleClick = () => {
    if (inputFileRef.current) {
      console.log("Opening file dialog...");
      inputFileRef.current.click(); // Trigger the file input dialog
    }
  };


  // const handlePrevious = (url) => {
  //   window.open(url, "_blank");
  //   onClick={() => handlePrevious(imagePath)}
  // };

  return (
    <>
      <div className="mainProfile">
        <div className="adminProfile">
          <div className="profileHeader primeHeader">
            <div className="row">
              <Title name={`Profile`} />
            </div>
          </div>
          <div className="profileMain p-4">
            <div className="row">
              <div
                className="adminProfileBox col-md-6 col-lg-4 col-sm-12 position-relative"
                style={{ borderRadius: "15px" }}
              >

            
                <div className="settingBox">
                  <div className="avatar avatar-xl relative">
                    <input
                      ref={inputFileRef}
                      name="image"
                      id="profileImage"
                      type="file"
                      hidden
                      accept="image/jpg, image/jpeg, image/png"
                      onChange={handleUploadImage}
                    /> 
                    <div className="container mt-2 relative" onClick={handleClick}>
                      <img
                        src={imagePath}
                        alt="Profile"
                        className="m-auto image mb-2"
                        style={{
                          width: "140px",
                          height: "140px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                      />
                      <div className="middle1" style={{
                        position: "absolute",
                        top : 80,
                        right : 400,

                        
                      }}>
                        <i className="fas fa-edit fa-lg text-primary"></i>
                      </div>
                    </div>
                  </div>


                  <div className="fs-4 text-center fw-bold">{admin.name}</div>
                  <div className="d-flex justify-content-center align-items-center"
                  style={{
                    marginLeft : "50px"
                  }}
                  >
                    <Button
                      btnName={`Upload Image`}
                      onClick={handleUpdateImage}
                      btnColor={`bg-success text-white`}
                      newClass={`mt-2 my-auto`}
                      style={{
                        // width: "200px",
                        borderRadius: "5px",
                        pointerEvents:
                          !image || image.length === 0 ? "none" : "auto",
                        opacity: !image || image.length === 0 ? 0.5 : 1,
                      }}
                      disabled={!image || image.length === 0}
                    />
                  </div>
                  {/* <div className="text-center text-danger mt-1">
                {!image || image.length === 0 ? "* Please Select image " : ""}
                </div> */}

                  <hr className="textPrime w-100 mt-3" />
                  <div className="mt-3">
                    <div className="adminField">
                      <Button
                        btnName={`Edit Profile`}
                        onClick={() => setBoxType(false)}
                        btnColor={` text-white ${
                          boxType === false ? "bg-dark" : "bg-secondary"
                        }`}
                        newClass={`py-3`}
                        style={{ width: "100%", borderRadius: "5px" }}
                      />
                    </div>
                    <div className="adminField">
                      <Button
                        btnName={`Change Password`}
                        onClick={() => setBoxType(true)}
                        btnColor={``}
                        newClass={` text-white mt-3 py-3 ${
                          boxType === true ? "bg-dark" : "bg-secondary"
                        }`}
                        style={{ width: "100%", borderRadius: "5px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {boxType == false && (
                <div className="adminProfileBox col-md-6 col-lg-8 col-sm-12 ">
                  <div className="settingBox">
                    <div className="editProfile">
                      <div className="text-center fw-bold fs-3 py-2">
                        Edit Profile
                      </div>
                      <hr className="textPrime w-75 m-auto pb-5" />

                      <div className="d-grid gap-md-5 gap-sm-3">
                        <Input
                          type={`text`}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          label={`Name`}
                          placeholder={`Admin Name`}
                          newClass={`w-75 m-auto`}
                          errorMessage={error.name}
                        />
                        <Input
                          type={`email`}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          label={`Email`}
                          placeholder={`Admin Email Id`}
                          newClass={`w-75 m-auto`}
                          errorMessage={error.email}
                        />
                        <Button
                          btnName={`Submit`}
                          onClick={handleEditName}
                          btnColor={`bg-success text-white`}
                          newClass={`mt-2 mx-auto`}
                          style={{
                            width: "100px",
                            borderRadius: "5px",
                            marginBottom: "70px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {boxType == true && (
                <div className="adminProfileBox col-md-6 col-lg-8 col-sm-12 ">
                  <div className="settingBox">
                    <div className="editPassword">
                      <div className="text-center fw-bold fs-3 py-2">
                        Change Password
                      </div>
                      <hr className="textPrime w-75 m-auto pb-5" />

                      <div className="d-grid gap-3">
                        <Input
                          type={`password`}
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          label={`Old Password`}
                          placeholder={`Old Password`}
                          newClass={`w-75 m-auto`}
                          errorMessage={error.oldPassword}
                        />
                        <Input
                          type={`password`}
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          label={`New Password`}
                          placeholder={`New Password`}
                          newClass={`w-75 m-auto`}
                          errorMessage={error.newPassword}
                        />
                        <Input
                          type={`password`}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          label={`Confirm New Password`}
                          placeholder={`Confirm New Password`}
                          newClass={`w-75 m-auto`}
                          errorMessage={error.confirmPassword}
                        />
                        <Button
                          btnName={`Submit`}
                          onClick={handleChangePassword}
                          btnColor={`bg-success text-white`}
                          newClass={`mt-2 mx-auto`}
                          style={{
                            width: "100px",
                            borderRadius: "5px",
                            marginBottom: "20px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(null, { getProfile, changePassword, profileUpdate })(
  AdminProfile
);
