
// import { closeDialog } from "../../store/dialogue";
import {
  createWithdrawMethod,
  updateWithdrawMethod,
} from "../../store/setting/setting.action";
import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import CancelIcon from "@mui/icons-material/Cancel";
import { baseURL } from "../../util/config";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "13px",
  border: "1px solid #C9C9C9",
  boxShadow: 24,
  p: "19px",
};


const AddWithdrawDialogue = () => {
  const { dialogue, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [addCategory, setAddCategory] = useState(false);
  const [name, setName] = useState();
  const [imagePath, setImagePath] = useState();
  const [image, setImage] = useState();
  const [detail, setDetail] = useState("");
  const [error, setError] = useState({
    name: "",
    image: "",
    detail: "",
  });

  console.log("dialogueData" , dialogueData)

  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogue) {
      setAddCategory(dialogue);
    }
  }, [dialogue]);
  useEffect(() => {
    if (dialogueData) {
      setName(dialogueData?.name);
      setImagePath(baseURL + dialogueData?.image);
      setDetail(dialogueData?.details);
    }
  }, [dialogue, dialogueData]);

  const handleCloseAddCategory = () => {
    setAddCategory(false);
    dispatch({ type: CLOSE_DIALOGUE });
  };

  const handleFileUpload = async (
    event
  ) => {
    const file = event.target.files && event.target.files[0];
    if (!file) return;
    setImage(file);

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImagePath(reader.result);
    });
    reader.readAsDataURL(file);
    setError({ ...error, image: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!name || (dialogueData ? "" : !image) || !detail) {
      let error = {};
      if (!name) error.name = "Name Is Required !";
      if (!image) error.image = "Image Is Required !";
      if (!detail) error.detail = "Detail Is Required !";
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", image);
      formData.append("details", detail);

      if (dialogueData) {
        let payload = {
          data: formData,
        };
        dispatch(updateWithdrawMethod(formData , dialogueData?._id));
      } else {
        dispatch(createWithdrawMethod(formData));
      }
    }
    dispatch({ type: CLOSE_DIALOGUE });
  };


  return (
    <>
      <Modal
        open={addCategory}
        onClose={handleCloseAddCategory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="create-channel-model ">
          <div className="d-flex justify-content-between">

            <Typography id="modal-modal-title" variant="h6" component="h2">
              {dialogueData
                ? "Update Payment Gateway Dialogue"
                : "Create Payment Gateway Dialogue"}
            </Typography>
            <CancelIcon
              onClick={handleCloseAddCategory}
              sx={{ fontSize: 35, color: "black", cursor: "pointer" }} />
          </div>

          <form className="mt-3">
            <Input
              label={"Name"}
              name={"name"}
              placeholder={"Enter Details..."}
              value={name}
              errorMessage={error.name && error.name}
              onChange={(e) => {
                setName(e.target.value);
                if (!e.target.value) {
                  return setError({
                    ...error,
                    name: `Name Is Required`,
                  });
                } else {
                  return setError({
                    ...error,
                    name: "",
                  });
                }
              }}
            />
            <div className="mt-2 add-details">
              <Input
                label={"Detail"}
                name={"detail"}
                placeholder={"Enter Details..."}
                value={detail}
                onChange={(e) => {
                  setDetail(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      detail: `Details Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      detail: "",
                    });
                  }
                }}
              />
            </div>
            <div>
              {error?.detail && <p className="errorMessage">{error?.detail}</p>}
              <span style={{ color: "red" }}>Note : Enter details Coma (,) separated string.</span>

            </div>
            <div className="mt-2 ">
              <Input
                type={"file"}
                label={"Image"}
                accept={"image/png, image/jpeg"}
                errorMessage={error.image && error.image}
                onChange={handleFileUpload}
              />
            </div>
            <div className=" mt-2 fake-create-img mb-2">
              {imagePath && (
                <img
                  src={imagePath}
                  style={{ width: "96px", height: "auto" }}
                />
              )}
            </div>
            <div className="mt-3 d-flex justify-content-end">
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime text-white`}
                style={{ borderRadius: "5px", width: "80px", marginLeft: "10px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />

            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddWithdrawDialogue;
