import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { approvedHostRequest, getPendingHost } from "../../store/host/host.action"
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import dayjs from "dayjs";
import { AcceptProductWarning } from "../../util/Alert";
import Male from "../../../assets/images/male.jpg";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { getPendingWithdrawRequest } from "../../store/withdraw/withdraw.action";


const AcceptedWithdrawRequest = () => {
    const [search, setSearch] = useState("ALL");
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([])
    const [acceptModal, setAcceptModal] = useState(false)
    const [declineModal, setDeclineModal] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [type, setType] = useState(2)
    const { dialogue, dialogueType, dialogueData } = useSelector(
        (state) => state.dialogue
    );
    const { withdrawRequest, total } = useSelector((state) => state.withdrawRequest)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getPendingWithdrawRequest(2))
    }, [dispatch])
    const handleFilterData = useMemo(() => {
        return (filteredData) => {
            if (typeof filteredData === "string") {
                setSearch(filteredData);
            } else {
                setData(filteredData);
            }
        };
    }, []);
    useEffect(() => {
        setData(withdrawRequest);
    }, [withdrawRequest]);
    useEffect(() => {
        setCurrentPage(1);
    }, [search]);

    // // pagination
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setCurrentPage(1);
    };
    const handleAccept = async (id) => {
        try {
            const data = await AcceptProductWarning("Accept");
            if (data) {
                const res = dispatch(approvedHostRequest({ hostId: id, type: 2 }));
                if (res?.status) {
                    toast.success(res.message);
                    dispatch(
                        getPendingHost(
                            search === "" ? "ALL" : search,
                            currentPage,
                            rowsPerPage,
                        )
                    );
                } else {
                    toast.error(res.message);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };
    const handleProfile = (row, type) => {
        dispatch({
            type: OPEN_DIALOGUE,
            payload: {
                type: type,
                data: row,
            },
        });

        let dialogueData_ = {
            dialogue: true,
            type,
            dialogueData: row,
        };

        localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
        localStorage.setItem("realHostInfo", JSON.stringify(dialogueData_));
    };

    const mapData = [
        {
            Header: "NO",
            width: "20px",
            Cell: ({ index }) => (
                <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
            ),
        },
        {
            Header: "Host",
            body: "profilePic",
            Cell: ({ row }) => (
                <div
                    style={{ cursor: "pointer" }}
                // onClick={() => handleProfile(row, "hostInfo")}
                >
                    <div className="d-flex justify-content-center px-2 py-1">
                        <div>
                            <img
                                src={row?.host?.profilePic || Male}
                                alt=""
                                loading="eager"
                                draggable="false"
                                style={{ borderRadius: "25px" }}
                                height={50}
                                width={50}
                                onError={(e) => e.target.src = Male}
                            />
                        </div>
                        <div className="d-flex flex-column justify-content-center text-start ms-3">
                            <b className="mb-0 text-sm text-capitalize ">{row?.host?.name}</b>
                            <div>
                                <div className="d-flex">
                                    {/* <PlaceIcon
                                        style={{ color: "rgb(225 78 132)", fontSize: "20px" }}
                                    /> */}
                                    <p
                                        className="text-xs text-secondary mb-0  text-capitalize"
                                        style={{ fontSize: "12px" }}
                                    >
                                        {row?.countryId?.name}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            Header: "Unique Id",
            body: "uniqueId",
            Cell: ({ row }) => (
                <span className="text-success">
                    {row.uniqueId ? row.uniqueId : "-"}
                </span>
            ),
        },
        {
            Header: "Gender",
            body: "gender",
            Cell: ({ row }) => <span>{row.gender ? row.gender : "Female"}</span>,
        },
        {
            Header: "Created At",
            body: "createdAt",
            Cell: ({ row }) => (
                <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
            ),
        },

    ];
    return (
        <>

            <div
                className="userTable "
                style={{
                    display: `${dialogueType === "hostInfo"
                        ? "none"
                        : dialogueType === "hostHistory"
                            ? "none"
                            : "block"
                        }`,
                }}
            >
                <div className="userHeader primeHeader">
                    <Title name={`Accepted WithdrawRequest`} />
                </div>
                <div className="primeHeader mt-4 row">
                    {/* <div className="d-flex justify-content-end col-md-6">
                    <Searching
                        type={"server"}
                        data={withdrawRequest}
                        setData={setData}
                        setSearchData={setSearch}
                        onFilterData={handleFilterData}
                        serverSearching={handleFilterData}
                        button={true}
                        column={mapData}
                        placeholder={"Searching Host..."}
                    />
                </div> */}
                </div>
                <div className="userMain">
                    <div className="tableMain mt-3">
                        <Table
                            data={data}
                            mapData={mapData}
                            serverPerPage={rowsPerPage}
                            serverPage={currentPage}
                            type={"server"}
                        // onChildValue={handleChildValue}
                        />
                    </div>

                    <Pagination
                        component="div"
                        count={total}
                        type={"server"}
                        serverPerPage={rowsPerPage}
                        totalData={total}
                        serverPage={currentPage}
                        onPageChange={handleChangePage}
                        setCurrentPage={setCurrentPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </>
    )
}
export default AcceptedWithdrawRequest