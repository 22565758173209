import React from "react"
import Button from "../../extra/Button"
import Input from "../../extra/Input"
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import CancelIcon from "@mui/icons-material/Cancel"
import { useDispatch, useSelector } from "react-redux";

const WithdrawInfo = () => {
    const dispatch = useDispatch();
    const { dialogueData } = useSelector(
        (state) => state.dialogue
    );
    // Seprate key and value from a paymentDetails 

    const arrayOfObjects = dialogueData.paymentDetails
        .flatMap((detail) => Object.entries(detail).map(([key, value]) => ({ key, value })));

    return (

        <div className="mainDialogue fade-in" style={{ zIndex: "9999999" }}>
            <div
                className="Dialogue"
                style={{ overflow: "auto", maxHeight: "100vh", width: "500px" }}
            >
                <div className="dialogueHeader">
                    <div className="headerTitle fw-bold">Payment Details Info</div>
                    <div
                        className="closeDialogue text-center"
                        onClick={() => {
                            dispatch({ type: CLOSE_DIALOGUE });
                        }}
                    >
                        <CancelIcon
                            sx={{ fontSize: 35, color: "black", cursor: "pointer" }}
                        />
                    </div>
                </div>
                <div
                    className="dialogueMain bg-white"
                    style={{
                        overflow: "auto",
                        borderRadius: "10px",
                        padding: "16px",
                    }}
                >
                    <div className="col-12 mt-2">
                        <div className="col-12 mt-3 text-about">
                            <Input
                                type={"text"}
                                label={"Payment Gateway"}
                                name={"Payment Gateway"}
                                value={dialogueData?.paymentGateway}
                                newClass={`mt-3`}
                                readOnly
                            />
                        </div>
                        {

                            arrayOfObjects?.map((item) => {
                                return (
                                    <div className="col-12 mt-1 text-about">
                                        <Input
                                            type={"text"}
                                            label={item?.key}
                                            name={"Payment Details"}
                                            value={item?.value}
                                            newClass={`mt-3`}
                                            readOnly
                                        />
                                    </div>
                                )
                            })}
                    </div>
                </div>
                <div className="dialogueFooter">
                    <div className="dialogueBtn">
                        <Button
                            btnName={`Close`}
                            btnColor={`bg-danger text-white`}
                            style={{ borderRadius: "5px", width: "80px" }}
                            onClick={() => {
                                dispatch({ type: CLOSE_DIALOGUE });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WithdrawInfo