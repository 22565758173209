import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import EditInfo from "../../../assets/images/Edit.png";
import QualityDialogue from "./QualityDialogue";
import { getQualityLevel } from "../../store/qualityLevel/qualitylevel.action";

const QualityLevel = (props) => {
    const { qualityLevel } = useSelector((state) => state.qualityLevel);
    const { dialogue, dialogueType, dialogueData } = useSelector(
        (state) => state.dialogue
    );

    console.log("qualityLevel" , qualityLevel)
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [search, setSearch] = useState("ALL");
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(getQualityLevel());
    }, [dispatch]);

    useEffect(() => {
        setData(qualityLevel);
    }, [qualityLevel]);

    // Active Plan

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setPage(0);
    };

    // Coin Plan Table
    const planTable = [
        {
            Header: "No",
            width: "20px",
            Cell: ({ index }) => <span>{index + 1}</span>,
        },

        {
            Header: "Level Name",
            body: "level name",
            Cell: ({ row }) => <span>{row?.levelName}</span>,
        },

        {
            Header: "Minimum Average Duaration (Second)",
            body: "minAvgDuration, ",
            Cell: ({ row }) => (
                <span>{row?.minAvgDuration ? row?.minAvgDuration : 0}</span>
            ),
        },

        {
            Header: "Minimum Effective Call",
            body: "minEffectiveCalls ",
            Cell: ({ row }) => (
                <span>{row?.minEffectiveCalls ? row?.minEffectiveCalls : 0}</span>
            ),
        },

       

        {
            Header: "Edit",
            body: "edit",
            Cell: ({ row }) => (
                <img
                    src={EditInfo}
                    height={30}
                    width={30}
                    alt="Edit"
                    onClick={() =>
                        dispatch({
                            type: OPEN_DIALOGUE,
                            payload: { data: row, type: "qualityLevel" },
                        })
                    }
                />
            ),
        },

        // add more columns as needed
    ];

    const handleFilterData = (filteredData) => {
        if (typeof filteredData === "string") {
            setSearch(filteredData);
        } else {
            setData(filteredData);
        }
    };

    return (
        <>
            <div className="mainPlanTable">
                <div className="planTable">
                    <div className="planHeader primeHeader">
                        <div className="col">
                            <Title name={`Quality Level`} />
                        </div>
                        <div className="row g-0 mt-3">
                            <div className="col-12 boxBetween ">
                                <div className="col-md-6 col-sm-12 my-2">
                                    <Button
                                        newClass={`border text-white userBtn`}
                                        btnColor={`bg-primary`}
                                        btnName={`Add`}
                                        btnIcon={`fas fa-plus fa-md`}
                                        onClick={() => {
                                            dispatch({
                                                type: OPEN_DIALOGUE,
                                                payload: { type: "qualityLevel" },
                                            });
                                        }}
                                        style={{
                                            borderRadius: "5px",
                                            width: "120px",
                                        }}
                                    />

                                    {dialogue && dialogueType === "qualityLevel" && <QualityDialogue />}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="userMain">
                        <div className="tableMain mt-3">
                            <Table
                                type={"client"}
                                data={data}
                                mapData={planTable}
                                PerPage={rowsPerPage}
                                Page={page}
                            />
                        </div>
                        <Pagination
                            type={"client"}
                            component="div"
                            count={qualityLevel?.length}
                            serverPage={page}
                            onPageChange={handleChangePage}
                            serverPerPage={rowsPerPage}
                            totalData={qualityLevel?.length}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        {/* <Table data={data} columns={planTable} searching={true} /> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, {
    QualityLevel,
})(QualityLevel);
