import React, { useEffect, useState } from "react";
import { CLOSE_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import Input from "../../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { permissionError } from "../../../util/Alert";
import Select from "react-select";
import Button from "../../../extra/Button";
import {
  createHost,
  updateHost,
  hostCountry,
  getAgencyDropDown,
} from "../../../store/host/host.action";
import ReactDropzone from "react-dropzone";
import { deleteHostImg } from "../../../store/FakeHost/fakeHost.action";
import Male from "../../../../assets/images/male.jpg";
import cancel from "../../../../assets/images/cancel.png";
import CancelIcon from "@mui/icons-material/Cancel"


const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "1px solid var(--inputBorder--)",
    borderRadius: "6px 0 0 6px",
    padding: "16px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#dee2e694",
  }),
};

const HostDialog = (props) => {
  const { host, country1, agencyDropdown } = useSelector((state) => state.host);
  const { dialogueData, extraData, dialogue } = useSelector(
    (state) => state.dialogue
  );
  const dispatch = useDispatch();
  const hasPermission = useSelector((state) => state.admin.admin.flag);

  useEffect(() => {
    dispatch(getAgencyDropDown());
    dispatch(hostCountry());
  }, [dispatch]);

  const initialCountry = {
    value: "647edf4a693e0fdc4a7705ff",
    label: "India",
  };

  const [country, setCountry] = useState(initialCountry);
  const [agencyName, setAgencyName] = useState({ value: "", label: "" });
  const [mongoId, setMongoId] = useState(0);
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [password, setPassword] = useState("");
  // const [country, setCountry] = useState({
  //   value: "",
  //   label: "",
  // });
  const [profilePic, setProfilePic] = useState("");
  const [imageType, setImageType] = useState(1);
  const [image, setImage] = useState([]);
  const [imgMulti, setImgMulti] = useState(false);
  const [imgText, setImgText] = useState();
  const [imagePath, setImagePath] = useState("");
  const [profilePicPath, setProfilePicPath] = useState("");
  const [countryName, setCountryName] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionAgency, setSelectedOptionAgency] = useState(null);
  const [error, setError] = useState({
    name: "",
    profilePic: "",
    image: "",
    password: "",
    country: "",
    agencyName: "",
    bio: "",
  });

  useEffect(
    () => () => {
      setMongoId("");
      setName("");
      setImage([]);
      setPassword("");
      setCountry("");
      setImagePath("");
      setProfilePicPath("");
      setAgencyName("");
      setBio("");
    },
    [dialogue]
  );

  useEffect(() => {
    if (dialogueData) {
      setImageType(dialogueData?.imageType);
      setMongoId(dialogueData?._id);
      setName(dialogueData?.name);
      setPassword(dialogueData?.password);
      setCountry({
        value: dialogueData?.countryId?._id,
        label: dialogueData?.countryId?.name,
      });
      // setGender(dialogueData?.gender);
      setBio(dialogueData?.bio);
      setProfilePicPath(dialogueData?.profilePic);
      setProfilePic(dialogueData?.profilePic);
      setAgencyName({
        value: dialogueData?.agencyId?._id,
        label: dialogueData?.agencyId?.name,
      });
      {
        dialogueData?.imageType == 0
          ? setImage(dialogueData?.image)
          : setImagePath(dialogueData?.image);
      }
    }
  }, [dialogueData]);

  useEffect(() => {
    if (!dialogueData) {
      if (imagePath?.length || image?.length) {
        setImgMulti(true);
      } else {
        setImgMulti(false);
      }
    } else {
      const fileObjects = [];
      image.forEach((item) => {
        if (typeof item === "object" && item.path && item.preview) {
          fileObjects.push(item);
        }
      });
      let path = null;
      if (typeof imagePath === "string") {
        path = imagePath.split(",");
      }
      const combinedString = dialogueData?.image.join(", ");
      const checkPath = combinedString === imgText;
      const imageCheck = dialogueData?.image?.length === image?.length;

      if (imageCheck === false || checkPath === false) {
        setImgMulti(true);
      }
      if (image?.length === 0 && imagePath?.length === 0) {
        setImgMulti(false);
      }
    }
  }, [image, imagePath]);

  const onPreviewDrop = (files) => {
    setError({ ...error, image: "" });
    files.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );
    setImage(image.concat(files));
  };
  const handleDeleteImg = (file123, index) => {
    const newFiles = [...image];
    image.splice(newFiles.indexOf(file123), 1);
    if (file123.preview) {
      const images = image.filter((ele) => {
        return ele.preview !== file123.preview;
      });
      setImagePath(images);
      setImage(images);
    } else {
      const newFiles = [...image];
      image.splice(newFiles.indexOf(file123), 1);
      setImage(newFiles);
    }
    if (mongoId) {
      props.deleteHostImg(dialogueData?._id, index);
    }
  };
  let name_ = []; // Initialize name_ as an array
  name_.unshift(name);

  const count = name_?.reduce((accumulator, element) => {
    const occurrences = element.split(",").length;
    return accumulator + occurrences;
  }, 0);

  let image_ = []; // Initialize image_ as an array
  image_.unshift(imagePath);
  const handleImage = (e) => {
    // BOTH ARE WORKING STEP : 1
    // if (e.target.files[0] == "") {
    //     setError({
    //       ...error,
    //       profilePic: "Image require",
    //     });

    // } else {
    //      setError({
    //        ...error,
    //        profilePic: "",
    //      })
    // }

    // BOTH ARE WORKING STEP : 2

    setError((prevErrors) => ({
      ...prevErrors,
      profilePic: "",
    }));
    setProfilePic(e.target.files[0]);
    setProfilePicPath(URL.createObjectURL(e.target.files[0]));
  };

  const isValidURL = (url) => {
    if (typeof url !== "string") {
      return false; // Make sure url is a string
    }

    const urlRegex = /^(ftp|http|https):\/[^ "]+$/;
    return urlRegex.test(url) || url?.startsWith("blob:");
  };

  const handleSubmit = (e) => {
    if (selectedOption?.value) {
      setCountry(selectedOption?.value);
    }

    if (
      !name ||
      !country ||
      !password ||
      !profilePicPath ||
      !agencyName ||
      (imageType === 1 ? !imagePath || !isValidURL(imagePath) : !image) ||
      (imageType === 0 && image.length === 0) ||
      !bio
    ) {
      let error = {};
      if (imageType === 1 ? !imagePath || !isValidURL(imagePath) : !image) {
        error.image = "Invalid Image Link!";
      }
      if (imageType === 0 && image.length === 0)
        error.image = "Image is Required !";
      if (imageType == 0 ? image?.length == 0 : !imagePath)
        error.image = "Image is Required !";
      if (!name) error.name = "Name is Required!";
      if (!password) error.password = "Password is Required !";
      if (!profilePicPath) error.profilePic = "Image is Required!";
      if (!agencyName) error.agencyName = "Agency name is Required!";
      if (!country) error.country = "Country name is required!";
      if (!bio) error.bio = "Bio name is required!";

      return setError({ ...error });
    } else {
      // if (!hasPermission) return permissionError();
      const countries = country?.value;

      const agencyName_ = agencyName?.value;
      const formData = new FormData();
      formData.append("name", name);
      formData.append("profilePic", profilePic);
      formData.append("imageType", imageType);
      formData.append("countryId", countries);
      formData.append("password", password);
      formData.append("bio", bio);
      formData.append("agencyId", agencyName_);
      // real host
      formData.append("type", 1);
      if (imageType == 1) {
        // formData.append("image", image);
        if (dialogueData) {
          if (typeof imagePath === "string" && imagePath.trim() !== "") {
            var path = imagePath.split(",") || [""];
            for (let i = 0; i < path.length; i++) {
              formData.append("image", path[i]);
            }
          }
        } else {
          if (typeof imagePath === "string" && imagePath.trim() !== "") {
            var path = imagePath.split(",") || [""];
            for (let i = 0; i < path.length; i++) {
              formData.append("image", path[i]);
            }
          }
        }
      } else {
        // if (!dialogueData) {
        const files = image?.filter((item) => typeof item === "object");
        for (let i = 0; i < files?.length; i++) {
          formData.append("image", files[i]);
        }
        // } else {
        //   formData.append("image", image);
        // }
      }

      if (mongoId) {
        props.updateHost(formData, mongoId);
      } else {
        props.createHost(formData);
      }

      dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  const showImg = (url) => {
    window.open(url, "_blank");
  };



  const options = country1.map((countryData) => ({
    value: countryData._id,
    label: countryData.name,
  }));

  useEffect(() => {
    // Set the initial country value after the component mounts
    const initialCountryData = country1.find(
      (country1) => country1._id === initialCountry.value
    );

    if (initialCountryData) {
      setCountry({
        value: initialCountryData._id,
        label: initialCountryData.name,
      });
    }
  }, [country1]);

  const optionsAgency = agencyDropdown?.map((agencyList) => {
    return {
      value: agencyList?._id,
      label: agencyList?.name,
    };
  });

  const handleChange = (e) => {
    const enteredValue = e.target.value.trim();
    if (!enteredValue) {
      setImagePath("");
    } else {
      if (enteredValue.endsWith(".mp4")) {
        setError({
          ...error,
          image: "MP4 videos are not allowed! Please enter a valid image link.",
        });
      } else {
        setImagePath(enteredValue);
        setImgText(enteredValue);
        setError({
          ...error,
          image: "",
        });
      }
    }
  };
  const handleClosed = () => {
    localStorage.removeItem("dialogueData");
    dispatch({ type: CLOSE_DIALOGUE });
  };

  return (
    <div className="mainDialogue fade-in" style={{ zIndex: "9999999" }}>
      <div
        className="Dialogue"
        style={{ overflow: "auto", maxHeight: "100vh", width: "800px" }}
      >
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Host Dialog</div>
          <div
            className=""
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <CancelIcon sx={{ fontSize: 35, color: "black", cursor: "pointer" }} />
          </div>
        </div>
        <div
          className="dialogueMain bg-white"
          style={{ overflow: "auto", borderRadius: "10px" }}
        >
          <div className="row">
            <div className="col-6">
              <Input
                label={`Host Name`}
                type={`text`}
                value={name}
                autoComplete="off"
                errorMessage={error.name && error.name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      name: `Name Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      name: "",
                    });
                  }
                }}
              />
            </div>

            {/* <div className="col-md-6 ">
              <div className="justify-content-start">
                <label className="mb-2 text-gray">Gender : </label>
                <div className="d-flex justify-content-evenly">
                  <div className="form-check">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="gender"
                      id="male"
                      value="Male"
                      checked={gender === "Male" ? true : false}
                      // onClick={(e) => {
                      //   setGender(e.target.value);
                      // }}
                      onChange={(e) => {
                        setGender(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            gender: "Gender is Required!",
                          });
                        } else {
                          return setError({
                            ...error,
                            gender: "",
                          });
                        }
                      }}
                    />
                    <label className="form-check-label" for="male">
                      Male
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="female"
                      value="Female"
                      checked={gender === "Female" ? true : false}
                      onChange={(e) => {
                        setGender(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            gender: "Gender is Required!",
                          });
                        } else {
                          return setError({
                            ...error,
                            gender: "",
                          });
                        }
                      }}
                    />
                    <label className="form-check-label" for="female">
                      Female
                    </label>
                  </div>
                </div>
              </div>
              {error.gender && (
                <div className="ml-2 mt-1">
                  {error.gender && (
                    <div className="pl-1 text__left">
                      <span className="text-error">{error.gender}</span>
                    </div>
                  )}
                </div>
              )}
            </div> */}
            {/* <div className="col-6">
              <Input
                label={`Password`}
                type={`password`}
                value={password}
                autoComplete="off"
                errorMessage={error.password && error.password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      password: `Password is required!`,
                    });
                  } else {
                    return setError({
                      ...error,
                      password: "",
                    });
                  }
                }}
              />
            </div> */}
            {/* <div className="col-6">
              <Input
                label={`Country`}
                id={`country`}
                type={`text`}
                value={country}
                errorMessage={error.country && error.country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      country: `country is required!`,
                    });
                  } else {
                    return setError({
                      ...error,
                      country: "",
                    });
                  }
                }}
              />
            </div> */}
            {/* <div className="col-md-6">
              <div className="form-group">
                <h6>Country</h6>

                <Select
                  value={country}
                  options={options}
                  styles={customStyles}
                  onChange={(selectedOption) => {
                    setCountry(selectedOption);
                    // Handle error
                  }}
                />
                <> */}
                  {/* <Select
                    value={country.value}
                    defaultValue={country}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption);
                      setError((prevError) => ({
                        ...prevError,
                        country: "",
                      }));
                    }}
                    options={options}
                  /> */}
                  {/* {!selectedOption?.value && (
                    <div className="ml-2 mt-1">
                      <p className="errorMessage">{error.country}</p>
                    </div>
                  )}
                </>
              </div>
            </div> */}

            <div className="form-group col-md-6">
              <h6>Agency</h6>

              <>
                <Select
                  defaultValue={agencyName}
                  value={agencyName}
                  styles={customStyles}
                  onChange={(selectedOptionAgency) => {
                    setAgencyName(selectedOptionAgency);
                    setError((prevError) => ({
                      ...prevError,
                      agencyName: "",
                    }));
                  }}
                  options={optionsAgency}
                />
                {!selectedOptionAgency?.value && (
                  <div className="ml-2 mt-1">
                    <p className="text-error">{error.agencyName}</p>
                  </div>
                )}
              </>
            </div>
            <div className={`form-group ${mongoId ? "col-md-6" : "col-12"}`}>
              <Input
                label={`Bio`}
                type={`text`}
                value={bio}
                autoComplete="off"
                errorMessage={error.bio && error.bio}
                onChange={(e) => {
                  setBio(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      bio: `bio Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      bio: "",
                    });
                  }
                }}
              />
            </div>
            {mongoId ? (
              <div className="col-md-6 ">
                <Input
                  label={`Unique Id`}
                  id={`uniqueId`}
                  type={`text`}
                  value={dialogueData.uniqueId}
                  errorMessage={error.uniqueId && error.uniqueId}
                  onChange={(e) => {
                    setCountry(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        uniqueId: `uniqueId is required!`,
                      });
                    } else {
                      return setError({
                        ...error,
                        uniqueId: "",
                      });
                    }
                  }}
                />
              </div>
            ) : (
              ""
            )}

            <div className="col-md-12 d-flex  mt-4">
              <label className="text-gray">Album Type : </label>
              <div class="col-3 d-flex align-items-center">
                <Input
                  id={`image`}
                  type={`radio`}
                  value={`0`}
                  name={`imageType`}
                  newClass={`me-2 mb-0`}
                  disabled={imgMulti}
                  onClick={(e) => {
                    setImageType(e.target.value);
                  }}
                  checked={imageType == 0 ? true : false}
                />
                <label class="form-check-label" for="image">
                  Image
                </label>
              </div>
              <div class=" col-3 d-flex align-items-center">
                <Input
                  type={`radio`}
                  id={`linkImage`}
                  value={`1`}
                  name={`imageType`}
                  disabled={imgMulti}
                  newClass={`me-2 mb-0`}
                  onClick={(e) => {
                    setImageType(e.target.value);
                  }}
                  checked={imageType == 1 ? true : false}
                />
                <label class="form-check-label" for="linkImage">
                  Link
                </label>
              </div>
            </div>
            <div className={imageType == 0 ? "row mt-4" : "d-none"}>
              <div className="col-lg-6">
                <label className="form-control-label" for="input-username">
                  Select (Multiple) image
                </label>
                {/* <Input
                      type={`file`}
                      accept={`image/*`}
                      onChange={handleImage}
                      newClass={`col-md-6 d-flex align-self-end`}
                      errorMessage={error.excelData && error.excelData}
                    /> */}
                <>
                  <ReactDropzone
                    onDrop={(acceptedFiles) => onPreviewDrop(acceptedFiles)}
                    accept="image/*"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="me-4">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div
                            style={{
                              height: 130,
                              width: 130,
                              border: "2px dashed gray",
                              textAlign: "center",
                              marginTop: "10px",
                            }}
                          >
                            <i
                              className="fas fa-plus"
                              style={{ paddingTop: 30, fontSize: 70 }}
                            ></i>
                          </div>
                          {/* <div className="img-container" style= {{display:"inline" , position:"relative" , float:"left"}}>
                                <i class="fas fa-times-circle text-danger" style= {{right:"10px" , position:"absolute" , float:"left" , top:"4px" ,cursor:"pointer" }}></i>
                                </div> */}
                        </div>
                      </section>
                    )}
                  </ReactDropzone>
                  {error.image && (
                    <div className="ml-2 mt-1">
                      {error.image && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{error.image}</span>
                        </div>
                      )}
                    </div>
                  )}
                </>
              </div>

              {/* <div
                  className="col-lg-6 ps-5 d-flex"
                  style={{ maxHeight: "190px"}}
                > */}
              <div
                className="col-lg-6 setImage ps-2"
                style={{ height: "170px" }}
              >
                <div
                  className="scroll-content w-100 flex-wrap"
                  style={{ height: "190px", overflow: "auto" }}
                >
                  {imageType == 0 ? (
                    <>
                      {image?.map((file123, index) => {
                        return (
                          <div key={index}>
                            <img
                              height="80px"
                              width="80px"
                              alt="app"
                              className="cursor mx-3"
                              src={
                                mongoId && !file123?.preview
                                  ? file123
                                  : file123?.preview ? mongoId && !file123?.preview
                                    ? file123
                                    : file123?.preview : Male
                              }
                              onClick={() =>
                                showImg(
                                  !file123?.preview ? file123 : file123?.preview
                                )
                              }
                              style={{
                                boxShadow:
                                  "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                marginTop: 10,
                                float: "left",
                                objectFit: "cover",
                              }}
                            />

                            <div className="img-container">
                              <i
                                className="fas fa-times-circle text-danger"
                                style={{ right: "10px" }}
                                onClick={() => handleDeleteImg(file123, index)}
                              ></i>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    imagePath && (
                      <>
                        <img
                          height="80px"
                          width="80px"
                          alt="app"
                          src={imagePath || Male}
                          className="cursor"
                          style={{
                            boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                            border: "2px solid #fff",
                            borderRadius: 10,
                            marginTop: 10,
                            float: "left",
                            objectFit: "cover",
                            marginRight: 15,
                          }}
                          onClick={() => showImg(imagePath)}
                        />
                      </>
                    )
                  )}
                </div>
                {/* {!dialogueData &&
                    !mongoId &&
                    image_.map((src, index) => {
                      const imageUrls = src?.split(",");
                      return imageUrls.map((imageUrl, i) =>
                        imageUrl ? (
                          <img
                            src={imageUrl}
                            alt={``}
                            key={`${index}-${i}`}
                            width={80}
                            height={80}
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              marginTop: 10,
                              // height:130,
                              // width:130,
                              float: "left",
                              objectFit: "cover",
                              marginRight: 15,
                            }}
                          />
                        ) : (
                          ""
                        )
                      );
                    })} */}
                {!dialogueData &&
                  !mongoId &&
                  image_.map((src, index) => {
                    if (typeof src === "string") {
                      const imageUrls = src.split(",");
                      return imageUrls.map((imageUrl, i) =>
                        imageUrl ? (
                          <img
                            src={imageUrl}
                            alt=""
                            key={`${index}-${i}`}
                            width={80}
                            height={80}
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              marginTop: 10,
                              float: "left",
                              objectFit: "cover",
                              marginRight: 15,
                            }}
                          />
                        ) : (
                          ""
                        )
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            </div>
            <div className="col-md-12">
              <div className={imageType == 1 ? "row mt-4 col-md-12" : "d-none"}>
                {/* <textarea
                    type="text"
                    rows={3}
                    cols={3}
                    className="form-control"
                    required=""
                    // defaultValue={image?.map((img) => img.preview).join(",")}
                    placeholder="Enter image link...."
                    value={image?.map((img) => img.preview).join(",")}
                    onChange={(e) => {
                      const enteredImages = e.target.value
                        .split(",")
                        .map((url) => ({
                          file: null,
                          preview: url.trim(),
                        }));
                      setImage(enteredImages);
                      if (!e.target.value) {
                        setError({
                          ...error,
                          image: "Image is Required!",
                        });
                      } else {
                        setError({
                          ...error,
                          image: "",
                        });
                      }
                    }}
                  /> */}
                <textarea
                  type="text"
                  rows={3}
                  cols={3}
                  className="form-control"
                  required=""
                  placeholder="Enter image link...."
                  value={imagePath}
                  onChange={handleChange}
                />
                {error.image && (
                  <div className="ml-2 mt-1">
                    {error.image && (
                      <div className="pl-1 text__left">
                        <span className="text-red">{error.image}</span>
                      </div>
                    )}
                  </div>
                )}

                {!mongoId && (
                  <>
                    <div className="d-flex mt-2">
                      <span className="text-danger">Note : </span>
                      <span className="" style={{ color: "red" }}>
                        You can add multiple image Link separate by comma (,)
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <Input
                label={`Profile Image`}
                id={`profilePicPath`}
                type={`file`}
                accept={`image/*`}
                onChange={(e) => handleImage(e)}
                errorMessage={error.profilePic && error.profilePic}
              />
              {profilePicPath && (
                <div className="d-flex">
                  <img
                    src={profilePicPath || Male}
                    alt="banner"
                    draggable="false"
                    className="m-0"
                    style={{
                      width: "110px",
                      height: "110px",
                      borderRadius: "8px",
                    }}
                    onError={(e) => e.target.src = Male}
                    onClick={() => showImg(profilePicPath)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={handleClosed}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  createHost,
  updateHost,
  getAgencyDropDown,
  hostCountry,
  deleteHostImg,
})(HostDialog);
