import React, { useState } from "react";
import "animate.css";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import {
    createIncome,
    updateIncomePlan,
} from "../../store/income/income.action";
import Cancel from "../../../assets/images/cancel.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { createQualityLevel, updateQualityLevel } from "../../store/qualityLevel/qualitylevel.action";

const QualityDialogue = (props) => {
    const dispatch = useDispatch();
    const { dialogueData } = useSelector((state) => state.dialogue);
    const { income } = useSelector((state) => state.income);
    const [mongoId, setMongoId] = useState(0);
    const [weeklyIncome, setWeeklyIncome] = useState(0);
    const [privateCallPrice, setPrivateCallPrice] = useState(0);
    // const [matchCallPrice, setMatchCallPrice] = useState(0);
    const [level, setLevel] = useState(income?.length + 1);
    const [callBonus, setCallBonus] = useState(0);
    const [levelName, setLevelName] = useState("");
    const [minAvgDuration, setMinAverageDuration] = useState("");
    const [minEffectiveCalls, setMinEffectiveCalls] = useState("");
    const [error, setError] = useState({
        // matchCallPrice: "",
        levelName: "",
        minAvgDuration: "",
        minEffectiveCalls: "",
    });

    useEffect(() => {
        setLevel(dialogueData?.level || income?.length + 1 || 0);
        setMongoId(dialogueData?._id);
        setLevelName(dialogueData?.levelName)
        setMinAverageDuration(dialogueData?.minAvgDuration);
        setMinEffectiveCalls(dialogueData?.minEffectiveCalls)
    }, [dialogueData]);


    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            !levelName ||
            !minAvgDuration ||
            !minEffectiveCalls
        ) {
            const error = {};

            if (!levelName) {
                error.levelName = "Level Name is required!";
            }
            // if (!matchCallPrice) {
            //   error.matchCallPrice = "MatchCall Price is required!";
            // }
            if (!minAvgDuration) {
                error.minAvgDuration = "Minimum Average Duration is Required!";
            }
            if (!minEffectiveCalls) error.minEffectiveCalls = "Minimum Effective Calls is required!";

            return setError({ ...error });
        }

        const data = {
            levelName,
            minAvgDuration: parseInt(minAvgDuration),
            minEffectiveCalls: parseInt(minEffectiveCalls),
        };

        if (mongoId) {
            const updateData = {
                levelName,
                minAvgDuration: parseInt(minAvgDuration),
                qualityLevelId: mongoId,

                minEffectiveCalls: parseInt(minEffectiveCalls),
            };
            dispatch(updateQualityLevel(updateData, mongoId));
        } else {
            dispatch(createQualityLevel(data));
        }
        dispatch({ type: CLOSE_DIALOGUE });
    };

    return (
        <>
            <div className="mainDialogue fade-in">
                <div className="Dialogue">
                    <div className="dialogueHeader">
                        <div className="headerTitle fw-bold">Quality Level</div>
                        <div
                            className="closeDialogue text-center"
                            onClick={() => {
                                dispatch({ type: CLOSE_DIALOGUE });
                            }}
                        >
                            <CancelIcon sx={{ fontSize: 35, color: "black", cursor: "pointer" }} />
                            {/* <img src={cancel} height={30} width={30} alt="Edit" /> */}

                        </div>
                    </div>
                    <div
                        className="dialogueMain"
                        style={{ overflow: "auto", maxHeight: "100vh" }}
                    >
                        <div className="row">
                            <div className="col-6">
                                <Input
                                    label={`Level Name`}
                                    id={`levelName`}
                                    type={`text`}
                                    value={levelName}
                                    placeholder={`Enter Level Name`}
                                    onChange={(e) => {
                                        setLevelName(e.target.value);

                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                levelName: `Level Name Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                levelName: "",
                                            });
                                        }
                                    }}
                                    errorMessage={error.levelName && error.levelName}
                                />
                            </div>

                            <div className="col-6">
                                <Input
                                    label={`Minimum Average Duration (Second)`}
                                    id={`minAvgDuration`}
                                    type={`number`}
                                    value={minAvgDuration}
                                    placeholder={`Enter Minimum Average Duration`}
                                    errorMessage={error.minAvgDuration && error.minAvgDuration}
                                    onChange={(e) => {
                                        setMinAverageDuration(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                minAvgDuration: `Minimum Average Duration Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                minAvgDuration: "",
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    label={`Minimum Effective Call`}
                                    id={`minEffectiveCalls`}
                                    placeholder={`Enter Minimum Effective Calls`}
                                    type={`text`}
                                    value={minEffectiveCalls}
                                    errorMessage={
                                        error.minEffectiveCalls && error.minEffectiveCalls
                                    }
                                    onChange={(e) => {
                                        setMinEffectiveCalls(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                minEffectiveCalls: `Minimum Effective Call Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                minEffectiveCalls: "",
                                            });
                                        }
                                    }}
                                />
                            </div>
                            {/* <div className="col-6">
                <Input
                  label={`Match Call Price`}
                  id={`matchCallPrice`}
                  type={`number`}
                  value={matchCallPrice}
                  errorMessage={error.matchCallPrice && error.matchCallPrice}
                  onChange={(e) => {
                    setMatchCallPrice(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        matchCallPrice: `Match Call Price is required!`,
                      });
                    } else {
                      return setError({
                        ...error,
                        matchCallPrice: "",
                      });
                    }
                  }}
                />
              </div> */}
                        </div>
                        <div className="dialogueFooter">
                            <div className="dialogueBtn">
                                <Button
                                    btnName={`Submit`}
                                    btnColor={`btnBlackPrime`}
                                    style={{ borderRadius: "5px", width: "80px" }}
                                    newClass={`me-2`}
                                    onClick={handleSubmit}
                                />
                                <Button
                                    btnName={`Close`}
                                    btnColor={`bg-danger text-white`}
                                    style={{ borderRadius: "5px", width: "80px" }}
                                    onClick={() => {
                                        dispatch({ type: CLOSE_DIALOGUE });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { createIncome, updateIncomePlan })(
    QualityDialogue
);
