import axios from "axios";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";
import  ActionType, { CREATE_DAILY_REWARD, DELETE_DAILY_REWARD, EDIT_DAILY_REWARD, GET_DAILY_REWARD }  from "./dailyReward.type";

export const getDailyReward = (search) => (dispatch) => {
    apiInstanceFetch
        .get(`admin/dailyReward/fetchDailyReward`)
        .then((res) => {
            
            if (res.status === true) {
                dispatch({
                    type: GET_DAILY_REWARD,
                    payload: res.data,
                });
                // setToast("success", res.message);
            } else {
                setToast("error", res.message);
            }
        })
        .catch((error) => {
            setToast("error", error);
        });
};

export const createDailyReward = (data) => (dispatch) => {
    axios
        .post(`admin/dailyReward/createDailyReward`, data)
        .then((res) => {
            
            if (res.data.status) {
                dispatch({
                    type: CREATE_DAILY_REWARD,
                    payload: res.data.data,
                });
                setToast("success", "Daily Reward Created successfully");
            } else {
                setToast("error", res.data.message);
            }
        })
        .catch((error) => {
            console.log("error", error.message);
        });
};

export const deleteDailyReward = (id) => (dispatch) => {
    axios
        .delete(`admin/dailyReward/removeDailyReward?dailyRewardCoinId=${id}`)
        .then((res) => {
            if (res.data.status) {

                dispatch({
                    type: DELETE_DAILY_REWARD,
                    payload: id,
                });
                setToast("success", "Daily Reward deleted successfully!");
            } else {
                setToast("error", res.data.message);
            }
        })
        .catch((error) => console.log(error));
};

// Update Coin PLan
export const updateDailyReward = (data, id) => (dispatch) => {
    axios
        .patch(`admin/dailyReward/modifyDailyReward`, data)
        .then((res) => {
            
            if (res.data.status) {
                
                dispatch({
                    type: EDIT_DAILY_REWARD,
                    payload: { data: res.data.data, id },
                });
                setToast("success", "Daily Reward updated successfully");
            } else {
                setToast("error", res.data.message);
            }
        })
        .catch((error) => console.log("error", error.message));
};






