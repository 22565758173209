import React, { useState } from "react";
import "animate.css";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import {
  createNewCoinPlan,
  updateCoinPlan,
} from "../../store/coinPlan/coinPlan.action";
import CancelIcon from "@mui/icons-material/Cancel"
import  Cancel  from "../../../assets/images/cancel.png";

const CoinPlanDialogue = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [mongoId, setMongoId] = useState(0);
  const [coin, setCoin] = useState(0);
  const [extraCoin, setExtraCoin] = useState(0);
  const [dollar, setDollar] = useState(0);
  const [rupee, setRupee] = useState(0);
  const [platFormType, setPlatFormType] = useState("");
  const [tag, setTag] = useState("");
  const [productKey, setProductKey] = useState("");
  const [error, setError] = useState({
    coin: "",
    dollar: "",
    productKey: "",
    extraCoin: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setMongoId(dialogueData?._id);
    setCoin(dialogueData?.coin);
    setExtraCoin(dialogueData?.extraCoin);
    setDollar(dialogueData?.dollar);
    setRupee(dialogueData?.rupee);
    // setPlatFormType(dialogueData?.platFormType);
    setTag(dialogueData?.tag);
    setProductKey(dialogueData?.productKey);
  }, [dialogueData]);

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const coinValid = isNumeric(coin);
    const dollarValid = isNumeric(dollar);
    const rupeeValid = isNumeric(rupee);
    const extraCoinValid = isNumeric(extraCoin);

  if(
      coin === "" ||
      !coinValid ||
      rupee ==="" ||
      !rupeeValid ||
      !dollarValid ||
      dollar === "" ||
      !extraCoinValid||
      !productKey
    ) {
      const error = {};

      if (coin === "") {
        error.coin = "Coin is required!";
      } else if (!coinValid) {
        error.coin = "Invalid Coin!";
      }
      if (rupee === "") {
        error.rupee = "Rupee is required!";
      } else if (!rupeeValid) {
        error.rupee = "Invalid rupee!";
      }
      if (dollar === "") {
        error.dollar = "Dollar is required!";
      } else if (!dollarValid) {
        error.dollar = "Invalid dollar!";
      }
      if (!extraCoinValid) {
        error.extraCoin = "Invalid extra coin!";
      }

      if (!productKey) error.productKey = "Product Key is required!";

      return setError({ ...error });
    }

    const data = {
      coin,
      dollar,
      extraCoin: extraCoin ? extraCoin : 0,
      tag,
      productKey,
      rupee,
    };

    if (mongoId) {
      props.updateCoinPlan(data, mongoId);
    } else {
      props.createNewCoinPlan(data);
    }
    dispatch({ type: CLOSE_DIALOGUE });
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Plan</div>
            <div
              className="boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <CancelIcon sx={{ fontSize: 35, color: "black", cursor: "pointer" }} />
            </div>
          </div>
          <div
            className="dialogueMain"
            style={{ overflow: "auto", maxHeight: "100vh" }}
          >
            <div className="row">
              <div className="col-6">
                <Input
                  label={`Coin`}
                  id={`coin`}
                  type={`number`}
                  value={coin}
                  errorMessage={error.coin && error.coin}
                  onFocus={(e) => {
                    e?.target?.value == 0 ? setCoin("") : "";
                  }}
                  onChange={(e) => {
                    setCoin(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        coin: `Coin Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        coin: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Extra Coin`}
                  id={`extraCoin`}
                  type={`number`}
                  value={extraCoin}
                  errorMessage={error.extraCoin && error.extraCoin}
                  onFocus={(e) => {
                    e?.target?.value == 0 ? setExtraCoin("") : "";
                  }}
                  onChange={(e) => {
                    setExtraCoin(e.target.value);
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`INR(₹)`}
                  id={`rupee`}
                  type={`number`}
                  value={rupee}
                  errorMessage={error.rupee && error.rupee}
                  onFocus={(e) => {
                    e?.target?.value == 0 ? setRupee("") : "";
                  }}
                  onChange={(e) => {
                    setRupee(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        rupee: `Rupee is required!`,
                      });
                    } else {
                      return setError({
                        ...error,
                        rupee: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`USD($)`}
                  id={`dollar`}
                  type={`number`}
                  value={dollar}
                  errorMessage={error.dollar && error.dollar}
                  onFocus={(e) => {
                    e?.target?.value == 0 ? setDollar("") : "";
                  }}
                  onChange={(e) => {
                    setDollar(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        dollar: `Dollar is required!`,
                      });
                    } else {
                      return setError({
                        ...error,
                        dollar: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Tag`}
                  id={`tag`}
                  type={`text`}
                  value={tag}
                  placeholder={`Enter Tag`}
                  onChange={(e) => {
                    setTag(e.target.value);
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Product Key`}
                  id={`productKey`}
                  type={`text`}
                  value={productKey}
                  placeholder={`Enter Product Key`}
                  errorMessage={error.productKey && error.productKey}
                  onChange={(e) => {
                    setProductKey(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        productKey: `Product Key Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        productKey: "",
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="dialogueFooter">
              <div className="dialogueBtn">
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
                <Button
                  btnName={`Close`}
                  btnColor={`bg-danger text-white`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  onClick={() => {
                    dispatch({ type: CLOSE_DIALOGUE });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { createNewCoinPlan, updateCoinPlan })(
  CoinPlanDialogue
);
