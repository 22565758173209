import axios from "axios";
import * as ActionType from "./qualitylevel.type";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";

export const getQualityLevel = (search) => (dispatch) => {
    apiInstanceFetch
        .get(`admin/qualityLevel/fetchQualityLevels`)
        .then((res) => {
            
            if (res.staus === true) {
                dispatch({
                    type: ActionType.GET_QUALITY_LEVEL,
                    payload: res.qualityLevel,
                });
                // setToast("success", res.message);
            } else {
                setToast("error", res.message);
            }
        })
        .catch((error) => {
            setToast("error", error);
        });
};

export const createQualityLevel = (data) => (dispatch) => {
    axios
        .post(`admin/qualityLevel/addQualityLevel`, data)
        .then((res) => {

            if (res.data.status) {
                
                dispatch({
                    type: ActionType.CREATE_QUALITY_LEVEL,
                    payload: res.data.qualityLevel,
                });
                setToast("success", "Quality Level Create successfully");
            } else {
                setToast("error", res.data.message);
            }
        })
        .catch((error) => {
            console.log("error", error.message);
        });
};

// Update Coin PLan
export const updateQualityLevel = (data, id) => (dispatch) => {
    axios
        .patch(`admin/qualityLevel/updateQualityLevel?qualityLevelId=${id}`, data)
        .then((res) => {

            if (res.data.status) {
                
                dispatch({
                    type: ActionType.EDIT_QUALITY_LEVEL,
                    payload: { data: res.data.qualityLevel, id },
                });
                setToast("success", "Quality Level updated successfully");
            } else {
                setToast("error", res.data.message);
            }
        })
        .catch((error) => console.log("error", error.message));
};






