import { Link, useNavigate } from "react-router-dom";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ToggleSwitch from "../../extra/ToggleSwitch";
import Searching from "../../extra/Searching";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Pagination from "../../extra/Pagination";
import { getAgencySettlement } from "../../store/settlement/settlement.action";
import BonusInfo from "./BonusInfo";

const SettlementSolved = (props) => {
  // const { dialogue, dialogueType, dialogueData } = useSelector(
  //   (state) => state.dialogue
  // );
  const { agencySettlement, historyCount } = useSelector(
    (state) => state.settlement
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("ALL");
  const [type, setType] = useState(2);
  const [sort, setSort] = useState("_id");
  const [sortType, setSortType] = useState(0);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(0);
  };

  useEffect(() => {
    dispatch(
      getAgencySettlement(
        startDate,
        endDate,
        currentPage,
        rowsPerPage,
        search === "" ? "ALL" : search,
        type,
        sort,
        sortType
      )
    );
  }, [startDate, endDate, currentPage, rowsPerPage, search, sort, sortType]);

  useEffect(() => {
    setData(agencySettlement);
  }, [agencySettlement]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleLoadSettlementPage = (data) => {
    const state = {
      id: data?.agencyId?._id,
      type: "custom",
      startDate: data?.startDate,
      endDate: data?.endDate,
      agencyName: data?.agencyId?.name,
    };
    navigate("/admin/agencySettlement", { state: state });
  };
  const mapData = [
    {
      Header: "No",
      width: "20px",
      // Cell: ({ index }) => <span>{index + 1}</span>,
      Cell: ({ index }) => (
        <span>{currentPage * rowsPerPage + index + 1}</span>
      ),
    },
    { Header: "	Agency Code", body: "agencyId.code" },
    { Header: "Agency Name", body: "agencyId.name" },
    { Header: "Start Date", body: "startDate" },
    {
      Header: "End Date",
      Cell: ({ row }) => <span className="text-nowrap">{row.endDate}</span>,
    },
    {
      Header: "Revenue",
      body: "coinEarned",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span
          onClick={() => handleLoadSettlementPage(row)}
          className="cursor text-primary fw-bold"
        >
          {row?.coinEarned}
        </span>
      ),
    },
    {
      Header: "commission",
      body: "commissionCoinEarned",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span>
          {row.commissionCoinEarned} &nbsp;
          <span className="text-success">
            ({row.agencyCommisionPercentage + "%"})
          </span>
        </span>
      ),
    },
    {
      Header: "Total Revenue",
      body: "totalCoinEarned",
      sorting: { type: "server" },
    },
    
    {
      Header: "Penalty/Bonus",
      body: "penltyOrBonus",
      sorting: { type: "client" },
      Cell: ({ row }) => <span onClick={() => {
        dispatch({
          type: OPEN_DIALOGUE,
          payload: { type: "bonusInfo", data: row },
        });
      }} className={`cursor ${row?.bonusOrPenltyAmount > 0 ? "text-success" : row?.bonusOrPenltyAmount === 0 ? "text-dark" : "text-danger"}`}>{row?.bonusOrPenltyAmount}</span>

    },


    {
      Header: "Final",
      body: "amount",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span
          onClick={() => handleLoadSettlementPage(row)}
          className="cursor textPink fw-bold"
        >
          {row?.amount} &nbsp;
        </span>
      ),
    },
    {
      Header: "Amount",
      Cell: ({ row }) => (
        <span
          onClick={() => handleLoadSettlementPage(row)}
          className="cursor text-primary"
        >
          {row?.dollar.toFixed(2) + "$"}
        </span>
      ),
      sorting: { type: "server" },
    },
    {
      Header: "Pay",
      Cell: ({ row }) => (
        <span>
          <i class="fa-solid fa-circle-check fs-5 text-success" />
        </span>
      ),
    },

    // add more columns as needed
  ];
  const handleChildValue = (value) => {
    setSortType(sortType === -1 ? 1 : -1);
    setSort(value);
  };
  return (
    <>
      <div className="mainSettlementTable">
        {dialogue && dialogueType === "bonusInfo" && <BonusInfo />}
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`Solved Settlement`} />
            </div>
            <div className="d-flex justify-content-end col-md-12 ">
              <div className="col-6 my-3">
                <Searching
                  type={"server"}
                  data={agencySettlement}
                  setSearchData={setSearch}
                  setData={setData}
                  onFilterData={handleFilterData}
                  serverSearching={handleFilterData}
                  button={true}
                  column={mapData}
                  placeholder={"Searching User..."}
                />
              </div>
            </div>
          </div>
          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
                onChildValue={handleChildValue}
              />
            </div>
            <div className="paginationFooter">
              <Pagination
                component="div"
                count={historyCount}
                serverPage={currentPage}
                type={"server"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={historyCount}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
          <div className="userFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencySettlement })(SettlementSolved);
