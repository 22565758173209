import { key } from "../../util/config";
import { SetDevKey, setToken } from "../../util/setAuth";
import  { CREATE_DAILY_REWARD, DELETE_DAILY_REWARD, EDIT_DAILY_REWARD, GET_DAILY_REWARD } from "./dailyReward.type"


const initialState = {
    dailyReward: [],
    total: null,
    // totalCoin: null,
    totalDollar: null,
    history: [],
};

export const DailyCheckInRewardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DAILY_REWARD:
            return {
                ...state,
                dailyReward: action.payload,
            };
        case CREATE_DAILY_REWARD:
            let data = [...state.dailyReward];
            data.unshift(action.payload);
            return {
                ...state,
                dailyReward: data,
            };
        case EDIT_DAILY_REWARD:
            
            return {
                ...state,
                dailyReward: state.dailyReward.map((data) =>
                    data._id === action.payload.id ? action.payload.data : data
                ),
            };
        case DELETE_DAILY_REWARD:    
            return {
                ...state,
                dailyReward: state.dailyReward.filter(
                    (dailyReward) => dailyReward?._id !== action?.payload
                ),
            };
        default:
            return state;
    }
};
