import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import {
  getIncome,
  deleteCoinPlan,
  isActiveCoinPlan,
  isTopCoinPlan,
} from "../../store/income/income.action";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import EditInfo from "../../../assets/images/Edit.png";
import IncomeDialogue from "./IncomeDialogue";

const Income = (props) => {
  const { income } = useSelector((state) => state.income);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [search, setSearch] = useState("ALL");
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getIncome(search == "" ? "ALL" : search));
  }, [dispatch, search]);

  useEffect(() => {
    setData(income);
  }, [income]);

  // Active Plan

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Coin Plan Table
  const planTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },

    {
      Header: "Level",
      body: "level",  
      Cell: ({ row }) => <span>{row?.level ? row?.level : 0}</span>,
    },

    {
      Header: "Level Name",
      body: "level name",
      Cell: ({ row }) => <span>{row?.levelName}</span>,
    },

    {
      Header: "Weekly Income",
      body: "weekly income",
      Cell: ({ row }) => (
        <span>{row?.weeklyIncome ? row?.weeklyIncome : 0}</span>
      ),
    },

    {
      Header: "Private Call Price",
      body: "private call price",
      Cell: ({ row }) => (
        <span>{row?.privateCallPrice ? row?.privateCallPrice : 0}</span>
      ),
    },


    {
      Header: "Call Bonus",
      body: "Call Bonus",
      Cell: ({ row }) => <span>{row?.callBonus ? row?.callBonus : 0}</span>,
    },
    {
      Header: "Created Date",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <img
          src={EditInfo}
          height={30}
          width={30}
          alt="Edit"
          onClick={() =>
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "income" },
            })
          }
        />
      ),
    },

    // add more columns as needed
  ];

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className="planHeader primeHeader">
            <div className="col">
              <Title name={`Income Level`} />
            </div>
            <div className="row g-0 mt-3">
              <div className="col-12 boxBetween ">
                <div className="col-md-6 col-sm-12 my-2">
                  <Button
                    newClass={`border text-white userBtn`}
                    btnColor={`bg-primary`}
                    btnName={`Add`}
                    btnIcon={`fas fa-plus fa-md`}
                    onClick={() => {
                      dispatch({
                        type: OPEN_DIALOGUE,
                        payload: { type: "income" },
                      });
                    }}
                    style={{
                      borderRadius: "5px",
                      width: "120px",
                    }}
                  />

                  {dialogue && dialogueType === "income" && <IncomeDialogue />}
                </div>
                <div className="d-flex justify-content-end col-md-6 col-sm-12">
                  <Searching
                    type={"server"}
                    data={income}
                    setData={setData}
                    setSearchData={setSearch}
                    onFilterData={handleFilterData}
                    serverSearching={handleFilterData}
                    button={true}
                    column={planTable}
                    placeholder={"Searching Coin plan..."}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                type={"client"}
                data={data}
                mapData={planTable}
                PerPage={rowsPerPage}
                Page={page}
              />
            </div>
            <Pagination
              type={"client"}
              component="div"
              count={income?.length}
              serverPage={page}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={income?.length}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* <Table data={data} columns={planTable} searching={true} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getIncome,
})(Income);
