import axios from "axios";

import { setToast } from "../../util/toast";
import {
  GET_CATEGORY_WISE_GIFT,
  CREATE_GIFT,
  DELETE_GIFT,
  EDIT_GIFT,
  GET_GIFT,
  TOP_GIFT,
} from "./gift.type";

import { apiInstanceFetch } from "../../util/api";
const GiftClick = localStorage.getItem("GiftClick");

export const getGift = (categoryId) => (dispatch) => {
  apiInstanceFetch
    .get(
      categoryId === "ALL"
        ? `admin/gift/all`
        : `client/user/gift/categoryWiseGift?categoryId=${categoryId}`
    )
    .then((res) => {
      if (res.status) {  
        dispatch({ type: GET_GIFT, payload: res.gift });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

//is top switch
export const isTop = (mid, value) => (dispatch) => {
  axios
    .put(`/gift/${mid}`)
    .then((result) => {
      dispatch({ type: TOP_GIFT, payload: result.data.gift });
      if (result.data.status) {
        if (!value) setToast("success", "Gift Set Top Successfully ✔");
        else setToast("success", "Gift Unset Top Successfully ✔");
      } else {
        setToast("error", result.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
//create new gift
export const createNewGift = (data) => (dispatch) => {
  
  axios
    .post(`admin/gift`, data)
    .then((res) => {
        debugger
      if (res.data.status) {
        dispatch({ type: CREATE_GIFT, payload: res.data.gift });
        setToast("success", "Gift created successfully!");
     
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

//delete gift
export const deleteGift = (giftId) => (dispatch) => {
  axios
    .delete(`admin/gift/${giftId}`)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Gift deleted successfully!");
        dispatch({ type: DELETE_GIFT, payload: giftId });
        // setTimeout(() => {
        //   GiftClick !== null && (window.location.href = "/admin/gifts");
        // }, 1000);
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//edit gift
export const editGift = (data, giftId) => (dispatch) => {
  
  axios
    .patch(`admin/gift/${giftId}`, data)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: EDIT_GIFT,
          payload: { data: res.data.gift, id: giftId },
        });
        setToast("success", "Gift updated successfully!");
  
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
