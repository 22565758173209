import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";

import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import EditInfo from "../../../assets/images/Edit.png";
import CheckInRewardDialogue from "./CheckInRewardDialogue";
import Delete from "../../../assets/images/delete.png";
import { deleteDailyReward, getDailyReward } from "../../store/dailyReward/dailyReward.action";
import { warning } from "../../util/Alert";

// import IncomeDialogue from "./IncomeDialogue";

const CheckInReward = (props) => {
    const { dailyReward } = useSelector((state) => state.dailyReward);

    console.log("dailyReward", dailyReward);

    const { dialogue, dialogueType, dialogueData } = useSelector(
        (state) => state.dialogue
    );
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [search, setSearch] = useState("ALL");
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(getDailyReward());
    }, [dispatch, search]);

    useEffect(() => {
        setData(dailyReward);
    }, [dailyReward]);

    // Active Plan

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setPage(0);
    };

      const handleDelete = (id) => {
        const data = warning();
        data
          .then((isDeleted) => {
            if (isDeleted) {
                dispatch(deleteDailyReward(id));
            }
          })
          .catch((err) => console.log(err));
      };

    // Coin Plan Table
    const planTable = [
        {
            Header: "No",
            width: "20px",
            Cell: ({ index }) => <span>{index + 1}</span>,
        },

        {
            Header: "Daily Reward Coin",
            body: "daily reward coin",
            Cell: ({ row }) => <span>{row?.dailyRewardCoin ? row?.dailyRewardCoin : 0}</span>,
        },

        {
            Header: "Day",
            body: "day",
            Cell: ({ row }) => <span>{row?.day}</span>,
        },
        {
            Header: "Edit",
            body: "edit",
            Cell: ({ row }) => (
                <img
                    src={EditInfo}
                    height={30}
                    width={30}
                    alt="Edit"
                    onClick={() =>
                        dispatch({
                            type: OPEN_DIALOGUE,
                            payload: { data: row, type: "checkinreward" },
                        })
                    }
                />
            ),
        },

        {
            Header: "Delete",
            body: "delete",
            Cell: ({ row }) => (
                <img src={Delete} onClick={() => handleDelete(row?._id)} height={30} width={30} alt="Delete" />
            ),
        },

        // add more columns as needed
    ];

    const handleFilterData = (filteredData) => {
        if (typeof filteredData === "string") {
            setSearch(filteredData);
        } else {
            setData(filteredData);
        }
    };

    return (
        <>
            <div className="mainPlanTable">
                <div className="planTable">
                    <div className="planHeader primeHeader">
                        <div className="col">
                            <Title name={`Daily Check In Reward`} />
                        </div>
                        <div className="row g-0 mt-3">
                            <div className="col-12 boxBetween ">
                                <div className="col-md-6 col-sm-12 my-2">
                                    <Button
                                        newClass={`border text-white userBtn`}
                                        btnColor={`bg-primary`}
                                        btnName={`Add`}
                                        btnIcon={`fas fa-plus fa-md`}
                                        onClick={() => {
                                            dispatch({
                                                type: OPEN_DIALOGUE,
                                                payload: { type: "checkinreward" },
                                            });
                                        }}
                                        style={{
                                            borderRadius: "5px",
                                            width: "120px",
                                        }}
                                    />

                                    {dialogue && dialogueType === "checkinreward" && <CheckInRewardDialogue />}
                                </div>
                               
                            </div>
                        </div>
                    </div>

                    <div className="userMain">
                        <div className="tableMain mt-3">
                            <Table
                                type={"client"}
                                data={data}
                                mapData={planTable}
                                PerPage={rowsPerPage}
                                Page={page}
                            />
                        </div>
                        <Pagination
                            type={"client"}
                            component="div"
                            count={dailyReward?.length}
                            serverPage={page}
                            onPageChange={handleChangePage}
                            serverPerPage={rowsPerPage}
                            totalData={dailyReward?.length}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        {/* <Table data={data} columns={planTable} searching={true} /> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, {
    getDailyReward,
})(CheckInReward);
