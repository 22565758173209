import { setToast } from "../../util/toast";
import * as ActionType from "./withdraw.type";
import { apiInstanceFetch } from "../../util/api";
import axios from "axios";


export const getPendingWithdrawRequest = (type) => (dispatch) => {
    apiInstanceFetch
        .get(
            `admin/withdrawRequest/getAllWithdrawalRequests?type=${type}`
        )
        .then((res) => {
            
            if (res.status) {

                dispatch({
                    type: ActionType.GET_WITHDRAW_REQUEST,
                    payload: { data: res.data, total: res.total },
                });
            } else {
                setToast("error", res.message);
            }
        })
        .catch((error) => {
            setToast("error", error.message);
        });
};

export const approvedWithdrawRequest = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .patch(
                `admin/withdrawRequest/approveWithdrawalRequest?requestId=${id}`)
            .then((res) => {
                if (res.data.status) {
                        
                    dispatch({
                        type: ActionType.APPROVE_WITHDRAW_REQUEST,
                        payload: { data: res.data.data, id: id },
                    });
                    resolve(res.data);
                } else {
                    setToast("error", res.data.message);
                    reject(new Error(res.data.message));
                }
            })
            .catch((error) => {
                setToast("error", error.message);
                reject(error);
            });
    });
};

export const declineWithdrawRequest = (id, reason) => (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `admin/withdrawRequest/rejectWithdrawalRequest?requestId=${id}&reason=${reason}`
        )
        .then((res) => {
            
          if (res.data.status) {
            dispatch({
              type: ActionType.REJECTED_WITHDRAW_REQUEST,
              payload: { data: res.data.data, id },
            });
            // setToast("success", `${hostId.hostId} is Declined Successfully!`);
            resolve(res.data);
          } else {
            setToast("error", res.data.message);
            reject(new Error(res.data.message));
          }
        })
        .catch((error) => {
          setToast("error", error.message);
          reject(error);
        });
    });
  };