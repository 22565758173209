import swal from "sweetalert";
import { FaCheck } from "react-icons/fa";
export const alert = (title, data, type) => {
  return swal(title, data, type);
};

export const warning = () => {
  return swal({
    title: "Are You Sure!",
    icon: "warning",
    dangerMode: true,
    buttons: true,
  });
};

//Delete Warning for category
export const warningForText = (text) => {
  return swal({
    title: "Are You Sure!",
    text: text,
    icon: "warning",
    dangerMode: true,
    buttons: true,
  });
};

//For Permission
export const permissionError = () => {
  return swal({
    title: "This is Demo Host ,Opps! You don't have Permission.",
    icon: "error",
  });
};

export const AcceptProductWarning = (text) => {
  return swal({
    // title: "Are you sure?",
    title: "Would you like to accept host?",
    // text: text,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  });
};

export const AcceptWithdrawRequest= (text) => {
  return swal({
    // title: "Are you sure?",
    title: "Would you like to accept withdraw request?",
    // text: text,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  });
};