import React, { useEffect, useRef, useState } from "react";
import Male from "../../../../assets/images/men.png";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { baseURL } from "../../../util/config";
import {
  fetchOldSystemChats,
  fetchThumbList,
} from "../../../store/host/host.action";
import axios from "axios";
import Title from "../../../extra/Title";
import NoImage from "../../../../assets/images/noimage.png"; // Fallback image
import { useLocation, useNavigate } from "react-router-dom";
import { apiInstanceFetch } from "../../../util/api";

const ChatPage = () => {
  const { oldChats, thumbList, loading, error, start, limit } = useSelector(
    (state) => state.host
  );

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const navigate = useNavigate();
  const location = useLocation();

  console.log("location", location)

  const { admin } = useSelector((state) => state.admin);
  const [chatValue, setChatValue] = useState("");
  const [image, setImage] = useState(null);
  const [url, setURL] = useState("");
  const [messageValue, setMessageValue] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [isActive, setIsActive] = useState(0); // Track active chat index
  const [selectedChat, setSelectedChat] = useState(); // Track selected chat
  const [selectedImage, setSelectedImage] = useState(null); // Track the selected image for preview
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [chatData, setChatData] = useState("");
  const filterChatData = thumbList.filter((item) => item?.host?.name == location?.state?.row?.name)
  const hostId = selectedChat?.host?._id;

  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const adminId = admin?._id;

  console.log("url", url)
  console.log("selectedChat", selectedChat)
  console.log("filterChatData", filterChatData)



  useEffect(() => {
    dispatch(fetchThumbList(start, limit));
  }, []);

  useEffect(() => {
    if (thumbList.length > 0 && !selectedChat) {
      setSelectedChat(filterChatData[0]); // Set selectedChat to the first item in thumbList
    }
  }, [thumbList]);

  useEffect(() => {
    if (adminId && hostId) {
      dispatch(fetchOldSystemChats(adminId, hostId)); // Fetch chats for the selected host
    }
  }, [selectedChat, adminId, hostId]);

  useEffect(() => {
    setMessageValue(oldChats?.message);
  }, [oldChats, hostId]);

  const handleTypeHere = (e) => {
    setChatValue(e.target.value);
  };

  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };

  const filteredChats = thumbList.filter((chatList) => {
    const isNameMatch = chatList?.host?.name
      ?.toLowerCase()
      .includes(searchItem.toLowerCase());

    const isMessageMatch = chatList?.message?.some((msg) =>
      msg?.text?.toLowerCase().includes(searchItem.toLowerCase())
    );

    // Return true if either name or message matches
    return isNameMatch || isMessageMatch;
  });

  // Here Socket is Connected :-

  useEffect(() => {
    if (!socketRef.current && adminId) {
      socketRef.current = io(baseURL, {
        transports: ["websocket", "polling"],
        query: {
          adminRoom: adminId,
        },
      });

      socketRef.current.on("connect", () => {
        socketRef.current.emit("joinRoom", { adminRoom: adminId });
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.on("disconnect", () => {
          console.log("Disconnected from the server");
        });

        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [adminId]);


  // Message From Multiple Host :-

  useEffect(() => {
    if (socketRef?.current) {
      const handleNewMessage = (newMessage) => {
        if (!newMessage.hostId) {
          console.error("Invalid hostId in new message:", newMessage);
          return;
        }

        // If the new message is from the currently selected chat, update messageValue
        // if (newMessage.hostId === hostId) {
        //   setMessageValue((prev) => [...prev, newMessage]);
        // }
      };

      socketRef.current.on("sytemChatMessageForAdmin", handleNewMessage);

      return () => {
        socketRef.current?.off("sytemChatMessageForAdmin", handleNewMessage);
      };
    }
  }, [selectedChat]);

  useEffect(() => {
    const element = document.querySelector('.mainAdmin.me-4');
    if (element) {
      element.style.height = 'auto';
    }
    return () => {
      if (element) {
        element.style.height = ''; // Reset when the component unmounts
      }
    };
  }, []);

  const handleSendMessage = (e) => {
    e?.preventDefault();

    const messageData = {
      personType: 2,
      adminId: adminId,
      hostId: hostId,
      messageType: image ? 2 : 1,
      heading: "Admin",
      headingType: 0,
      text: chatValue ? chatValue : "",
      image: image ? url : "",
    };

    const stringifiedMessage = JSON.stringify(messageData);

    console.log("stringifiedMessage", stringifiedMessage)
    // Emit the message via socket.io
    if (socketRef.current) {
      socketRef.current.emit("sytemChatMessageForAdmin", stringifiedMessage);
    }
    setMessageValue((prev) => [...prev, messageData]);
    setChatValue("");
    setImage(null);
  };

  const handleImageChange = async (e) => {
    const fileInput = e.target;
    const file = fileInput.files[0];

    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(
        "admin/systemChat/handleChatImage",
        formData
      );

      const getUrl = response.data;
      setURL(getUrl?.imageUrl);
    } catch (error) {
      console.error("Error occurred while uploading the image:", error);
    }

    fileInput.value = "";
    setImage(file);
  };


  const handleFilePicker = () => {
    document.getElementById("fileInput").click();
  };

  const handleChatListActive = (index, chat) => {
    setIsActive(index);
    setSelectedChat(chat);
  };

  const handleImageClick = (image) => {
    const regex = /https?:\/\/[^\\]*\\.*\.(jpg|png|jpeg|gif)/;
    const isImage = regex.test(image)
    if (!isImage) {
      setSelectedImage(image);
    } else {
      setSelectedImage(NoImage)
    }
    setIsPreviewOpen(true);
  };


  const handleClosePreview = () => {
    setIsPreviewOpen(false);
    setSelectedImage(null);
  };



  return (
    <div className="chat-page">
      {/* Sidebar */}
      <div className="sidebar">
        <div className="section chats">
          <div className="sticky top-0">
            <Title name={`Chats`} />
            <div className="search-bar mt-2 ml-2">
              <input
                type="text"
                className="form-control"
                placeholder="Search ChatsList..."
                value={searchItem}
                onChange={handleSearch}
              />
            </div>
          </div>

          {filteredChats.map((chatList, i) => {

            const lastMessage =
              chatList?.message?.slice(-1)[0]?.text || "No messages available";

            const truncateMessage = (text) => {
              const words = text.split(" ");
              return words.length > 10
                ? words.slice(0, 3).join(" ") + "..."
                : text;
            };

            return (

              <div
                className={
                  location.pathname === "/admin/chat" 
                    ? selectedChat?.host?.name === chatList?.host?.name
                      ? "chat-itemActive"
                      : "chat-item"
                    : selectedChat?.host?.name === chatList?.host?.name
                      ? "chat-itemActive"
                      : "chat-item"
                }
                key={i}
                onClick={() => handleChatListActive(i, chatList)} // Update selected chat
              >
                {/* Check if the profile picture is a valid image URL */}
                {(() => {
                  const regex = /https?:\/\/[^\\]*\\.*\.(jpg|png|jpeg|gif)/;
                  const isImage = regex.test(chatList?.host?.profilePic);
                  return (
                    <img
                      src={isImage ? Male : chatList?.host?.profilePic} // Use Male as fallback if it's not an image
                      alt="User"
                      className="avatar"
                    />
                  );
                })()}

                <div className="chat-details">
                  <h6
                    className={
                      location.pathname === "/admin/chat"
                        ? selectedChat?.host?.name === chatList?.host?.name
                          ? "text-white" : "text-black"
                        : selectedChat?.host?.name === chatList?.host?.name
                          ? "text-white" : "text-black"
                    }
                    style={{
                      marginBottom: "0px",
                    }}
                  >
                    {chatList?.host?.name}
                  </h6>
                  <p className={
                    location.pathname === "/admin/chat"
                      ? selectedChat?.host?.name === chatList?.host?.name
                        ? "text-white" : "text-black"
                      : selectedChat?.host?.name === chatList?.host?.name
                        ? "text-white" : "text-black"
                  }>
                    {truncateMessage(lastMessage)}
                  </p>
                </div>
              </div>

            );
          })}
        </div>
      </div>

      <div className="chat-area">
        {selectedChat && (
          <>
            {/* Sticky Chat Header */}
            <div className="chat-header sticky-header" style={{ position: 'sticky', top: 0, zIndex: 100, background: '#fff' }}>
              <div className="user-info">
                {(() => {
                  const regex = /https?:\/\/[^\\]*\.(jpg|png|jpeg|gif)/;
                  const isImage = regex.test(selectedChat?.host?.profilePic);

                  return (
                    <img
                      src={isImage ? selectedChat?.host?.profilePic : Male} // Use Male as fallback if it's not an image
                      alt="User"
                      className="avatar"
                    />
                  );
                })()}
                <div className="mt-2">
                  <h6 className="mb-0">{selectedChat?.host?.name}</h6>
                </div>
              </div>
              <div className="chat-actions">
                <i className="icon-settings"></i>
                <i className="icon-video"></i>
              </div>
            </div>

            {/* Chat Messages */}
            <div
              className="chat-messages"
              style={{
                flex: 1,
                overflowY: 'auto',
                padding: '1rem',
                // marginTop: '10px',
                backgroundColor: '#f9f9f9',
              }}
            >
              {messageValue?.map((message, i) => (
                <div
                  className={`message ${message.personType === 1 ? 'received' : 'sent'}`}
                  key={i}
                >
                  <div className="d-flex align-items-start ml-2">
                    {message.personType === 1 ? (
                      <>
                        {message?.image ? (
                          <img
                            src={
                              typeof message.image === 'string' &&
                                message.image.includes('/storage\\')
                                ? Male
                                : message.image || Male
                            }
                            alt="User"
                            className="mb-2"
                            height={150}
                            width={150}
                            onClick={() => handleImageClick(image)}
                            style={{
                              objectFit: 'contain',
                            }}
                          />
                        ) : (
                          <p className="chat-submessage text-white">{message.text}</p>
                        )}
                      </>
                    ) : (
                      <>
                        {message?.image ? (
                          <img
                            src={
                              typeof message.image === 'string' &&
                                message.image.includes('/storage\\')
                                ? Male
                                : message.image || Male
                            }
                            onClick={() => handleImageClick(message?.image)}
                            alt="User"
                            className="mb-2"
                            height={150}
                            width={150}
                          />
                        ) : (
                          <p className="chat-submessage text-white">{message.text}</p>
                        )}
                      </>
                    )}
                  </div>

                  {isPreviewOpen && selectedImage && (
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                      }}
                      onClick={handleClosePreview}
                    >
                      <img
                        src={selectedImage || NoImage}
                        alt="Preview"
                        style={{
                          maxWidth: "90%",
                          maxHeight: "90%",
                          objectFit: "contain",
                          border: "2px solid white",
                        }}
                      />
                    </div>
                  )}

                  <style jsx>{`
                .snapshot-item:hover {
                    transform: scale(1.05); /* Zoom effect */
                }

                .snapshot-item:hover .snapshot-caption {
                    opacity: 1; /* Show caption on hover */
                }
            `}</style>
                </div>
              ))}
            </div>

            {image && (
              <div style={{ display: "flex", justifyContent: "start" }}>
                <img
                  src={url}
                  onClick={() => handleImageClick(image)}
                  alt="Image"
                  height={100}
                  width={100}
                />
              </div>
            )}

            {/* Sticky Chat Input */}
            <form
              className="chat-input sticky-headerbottom"
              style={{
                position: 'sticky',
                bottom: 0,
                background: '#fff',
                zIndex: 100,
                padding: '0.5rem',
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
              }}
              onSubmit={handleSendMessage}
            >
              <input
                type="text"
                placeholder="Type your message here..."
                className="form-control border-0 shadow-none"
                value={chatValue}
                onChange={handleTypeHere}
              />
              <i
                className="bi bi-paperclip mx-2"
                style={{ fontSize: '20px' }}
                onClick={handleFilePicker}
              ></i>
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
              <button
                className="btn d-flex align-items-center text-white ps-3 pe-3"
                style={{
                  backgroundColor: '#191919',
                }}
              >
                Send
                <i
                  className="bi bi-send ms-1 text-white"
                  style={{
                    fontSize: '13px',
                    marginLeft: '10px',
                  }}
                ></i>
              </button>
            </form>
          </>
        )
        }
      </div>

    </div>
  );
};

export default ChatPage;