import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navigator = (props) => {
  const location = useLocation();

  const { name, path, path2, icon, navIcon, onClick, navIconImg, navSVG, liClass } = props;

  console.log("path2" , path2);

  return (
    <ul className="mainMenu">
      <li onClick={onClick} className={liClass}>
        <Tooltip title={name} placement="right">
          <Link
            to={{ pathname: path }}
            className={`${(location.pathname === path || location.pathname === path2) && "activeMenu"}`}
          >
            <div>
              {navIconImg ? (
                <>
                  <img src={navIconImg} />
                </>
              ) : navIcon ? (
                <>
                  <i className={navIcon}></i>
                </>
              ) : (
                <>{navSVG}</>
              )}
              <span className="text-capitalize">{name}</span>
            </div>
            {props?.children && <svg xmlns="http://www.w3.org/2000/svg" height={15} width={15} viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" /></svg>
            }
          </Link>
        </Tooltip>
        {/* If Submenu */}
        <ul className={`subMenu transform0`}>
          {props.children?.map((res) => {
            const { subName, subPath, onClick } = res?.props;
            return (
              <>
                <Tooltip title={subName} placement="right">
                  <li onClick={onClick}>
                    <Link
                      to={{ pathname: subPath }}
                      className={`${location.pathname === subPath && "activeMenu"
                        }`}
                    // onClick={onClick}
                    >
                      <i className="fa-solid fa-circle"></i>
                      <span style={{ fontSize: "14px" }}>{subName}</span>
                    </Link>
                  </li>
                </Tooltip>
              </>
            );
          })}
        </ul>
      </li>
    </ul>
  );
};


export default Navigator;
