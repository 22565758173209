import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { approvedHostRequest, getPendingHost } from "../../store/host/host.action"
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import { AcceptProductWarning } from "../../util/Alert";
import Male from "../../../assets/images/male.jpg";
import dayjs from "dayjs";
import { getPendingWithdrawRequest } from "../../store/withdraw/withdraw.action";


const DeclineWithdrawRequest = () => {
    const [search, setSearch] = useState("ALL");
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([])
    const [acceptModal, setAcceptModal] = useState(false)
    const [declineModal, setDeclineModal] = useState(false)
    const [type, setType] = useState(3)
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const { withdrawRequest, total } = useSelector((state) => state.withdrawRequest)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getPendingWithdrawRequest(3))
    }, [dispatch])

    const handleFilterData = useMemo(() => {
        return (filteredData) => {
            if (typeof filteredData === "string") {
                setSearch(filteredData);
            } else {
                setData(filteredData);
            }
        };
    }, []);
    useEffect(() => {
        setData(withdrawRequest);
    }, [withdrawRequest]);
    useEffect(() => {
        setCurrentPage(1);
    }, [search]);

    // // pagination
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setCurrentPage(1);
    };
    const handleAccept = async (id) => {
        try {
            const data = await AcceptProductWarning("Accept");
            if (data) {
                const res = dispatch(approvedHostRequest({ hostId: id, type: 2 }));
                if (res?.status) {
                    toast.success(res.message);
                    dispatch(
                        getPendingHost(
                            search === "" ? "ALL" : search,
                            currentPage,
                            rowsPerPage,
                        )
                    );
                } else {
                    toast.error(res.message);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const mapData = [
        {
            Header: "NO",
            width: "20px",
            Cell: ({ index }) => (
                <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
            ),
        },
        {
            Header: "Host",
            body: "profilePic",
            Cell: ({ row }) => (
                <div
                    style={{ cursor: "pointer" }}
                    // onClick={() => handleProfile(row, "hostInfo")}
                >
                    <div className="d-flex justify-content-center px-2 py-1">
                        <div>
                            <img
                                src={row?.host?.profilePic || Male}
                                alt=""
                                loading="eager"
                                draggable="false"
                                style={{ borderRadius: "25px" }}
                                height={50}
                                width={50}
                                onError={(e) => e.target.src = Male}
                            />
                        </div>
                        <div className="d-flex flex-column justify-content-center text-start ms-3">
                            <b className="mb-0 text-sm text-capitalize ">{row?.host?.name}</b>
                            <div>
                                <div className="d-flex">
                                    {/* <PlaceIcon
                                        style={{ color: "rgb(225 78 132)", fontSize: "20px" }}
                                    /> */}
                                    <p
                                        className="text-xs text-secondary mb-0  text-capitalize"
                                        style={{ fontSize: "12px" }}
                                    >
                                        {row?.countryId?.name}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            Header: "Unique Id",
            body: "uniqueId",
            Cell: ({ row }) => (
                <span className="text-success">
                    {row.uniqueId ? row.uniqueId : "-"}
                </span>
            ),
        },
        {
            Header: "Gender",
            body: "gender",
            Cell: ({ row }) => <span>{row.gender ? row.gender : "Female"}</span>,
        },

        {
            Header: "Created At",
            body: "createdAt",
            Cell: ({ row }) => (
                <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
            ),
        },

    ];
    return (
        <>
            {
                acceptModal && (
                    <div className="mainDialogue fade-in" style={{ zIndex: "9999999" }}>
                        <div
                            className="Dialogue"
                            style={{ overflow: "auto", maxHeight: "100vh", width: "800px" }}
                        >
                            <div className="dialogueHeader">
                                <div className="headerTitle fw-bold">Host Dialog</div>
                                <div
                                    className="closeBtn "
                                    onClick={() => {
                                        dispatch({ type: CLOSE_DIALOGUE });
                                    }}
                                >
                                    <i className="fa-solid fa-xmark ms-2"></i>
                                </div>
                            </div>
                            <div
                                className="dialogueMain bg-white"
                                style={{ overflow: "auto", borderRadius: "10px" }}
                            >

                            </div>
                        </div>
                    </div>
                )
            }

            <div className="userHeader primeHeader">
                <Title name={`Rejected WithdrawRequest`} />
            </div>
            <div className="primeHeader mt-4 row">
                {/* <div className="d-flex justify-content-end col-md-6">
                    <Searching
                        type={"server"}
                        data={withdrawRequest}
                        setData={setData}
                        setSearchData={setSearch}
                        onFilterData={handleFilterData}
                        serverSearching={handleFilterData}
                        button={true}
                        column={mapData}
                        placeholder={"Searching Host..."}
                    />
                </div> */}
            </div>
            <div className="userMain">
                <div className="tableMain mt-3">
                    <Table
                        data={data}
                        mapData={mapData}
                        serverPerPage={rowsPerPage}
                        serverPage={currentPage}
                        type={"server"}
                    // onChildValue={handleChildValue}
                    />
                </div>

                <Pagination
                    component="div"
                    count={total}
                    type={"server"}
                    serverPerPage={rowsPerPage}
                    totalData={total}
                    serverPage={currentPage}
                    onPageChange={handleChangePage}
                    setCurrentPage={setCurrentPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    )
}
export default DeclineWithdrawRequest