
import * as ActionType from "./withdraw.type";

const initialState = {
    withdrawRequest: [],
    total: null,
    history: [],
    loading: false,
    thumbList: [],
    oldChats: [],
    error: null,
    start: 0,
    limit: 10,
};

export const withdrawRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.GET_WITHDRAW_REQUEST:
            
            return {
                ...state,
                withdrawRequest: action.payload.data,
                  total: action.payload.total,
            };

        case ActionType.APPROVE_WITHDRAW_REQUEST:
            
            return {
                ...state,
                withdrawRequest:
                    state.withdrawRequest.filter((request) => request?._id !== action?.payload?.id)
            };

        case ActionType.REJECTED_WITHDRAW_REQUEST:
            
            return {
                ...state,
                withdrawRequest:
                    state.withdrawRequest.filter((request) => request?._id !== action?.payload?.id)
            };

        default:
            return state;
    }



}